.training-page {
  margin-bottom: 5rem;

  & .page-title h1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  } 

  & button {
    font-size: 1rem;
  }
  & .gsta-table-complete th.action {
    width: 12%;
  }

  & button:disabled {
    & i:last-child {
      animation: rotation 1s linear infinite;
    }
  }

  & .redirection-link {
    font-weight: normal;
    font-size: 1rem;
    margin-top: 0.5rem;
    justify-content: flex-start;
  }
}

