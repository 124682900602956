.simulator-table-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  &-simulator-icon {
    margin-bottom: 0.4rem;
  }
  & > span {
    margin-left: 0.5rem;
  }
}