@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.tooltip {
  display: flex;
  & .tooltip-content {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    z-index: 100;
    font-size: 1rem;
    visibility: hidden;
    background-color: $layout-dark;
    color: $text-color-primary;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    transition: opacity 0.2s ease-in-out;
    border: 1px solid $primary;
  }
}

.tooltip:hover .tooltip-content {
  visibility: visible;
  color: inherit;
}
