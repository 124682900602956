@import "../../styles/variables/color";

.export-pdf-modal-list{
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y:scroll;
  max-height: 15rem;
  &_title {
   display: flex;
   flex-direction: column;
   margin-bottom: 0.5rem;
   position: sticky;
   background-color: $layout-dark;
   top:0;
   z-index: 3;
   padding: 0.5rem 0rem;
   gap: 0.3rem;
   border-bottom: 2px solid $text-color-inversed;
   & span{
    display:flex;
    gap: 0.5rem;
    align-items: center;
   }
   & span.border{
    padding: 0 0.5rem;
   }
   & span[isvisible='true'] {
    visibility: visible;
    color: $invalid;
   }

   & span[isvisible='false']{
    visibility: hidden;
   }
  }

  & article {
   display: flex;
   align-items: center;
   & .acreos-check-box {
    border: 2px solid $text-color-disabled;
    &.checked{
      border-color: $primary;
    }
   }
  }
}

.export-pdf-modal-list[error='true']{
  border: 1px solid $invalid
}

.export-pdf-modal-list[error='false']{
  border: 1px solid $text-color-disabled;
}