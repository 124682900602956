$text-color-disabled: #a09e9e;

.select-training-as-template {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  & > h1 {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid $text-color-disabled;
    & > i {
      font-size: 2rem;
    }
  }
  & > section {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    & button {
      margin-left: 0rem;
    }
    & div {
      display: flex;
      align-items: center;
      & div {
        font-weight: bold;

        & span:nth-child(2) {
          font-weight: normal;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
