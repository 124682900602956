.month-activity {
  margin-bottom: 2rem;
  & h3 {
    display: flex;
    align-items: center;
    & i {
      margin-left: 1rem;
    }
  }
}
 
.activity-card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  &-simulator {
    @extend .activity-card-grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

.activity-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: nowrap;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    align-content: stretch;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.calendar-container {
  min-width: 50%;
}

.home-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  & .acreos-date-picker {
    width: fit-content;
  }
}
