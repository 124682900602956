@import "../../styles/variables/color";

.pdf-visualiser {
  & .background {
    position: fixed;
    z-index: 10;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(1, 1, 1, 0.6);
    & > div {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 5rem;
      & > figure {
        position: relative;
        width: fit-content;
        & button {
          position: absolute;
          right: -1rem;
          top: -1rem;
          padding: 0.5rem;
          border-radius: 5rem;
          border: none;
          background-color: $invalid;
          color: $text-color-primary;
          &:hover {
            cursor: pointer;
          }
        }
        & > div {
          position: absolute;
          bottom: 30px;
          left: 0;
          right: 0;
          font-size: 10px;
          & p {
            color: black;
            margin: 0;
          }
        }
        & .logo {
          & > img {
            max-height: 75px;
          }
          display: flex;
          justify-content: right;
          align-items: center;
          height: 75px;
          position: absolute;
          right: 0;
          top: 15px;
          margin:15px 50px;
        }
      }
    }
  }
}
