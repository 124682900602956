@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.gsta-table-shadow {
  border-radius: 1rem;
  box-shadow: 2px 2px 10px 0 $layout-dark-shadow;
}

.gsta-table-select {
  min-width: 5rem;
}

.gsta-table-complete {
  background-color: $layout-dark;
  text-align: center;
  width: 100%;
  border: $layout-main;
  border-collapse: collapse;
  table-layout: fixed;

  &_header {
    display: flex;
    justify-content: center;
    align-items: center;

    & i {
      font-size: 1.1rem;
    }
  }

  & th {
    text-align: center;
    border-bottom: 2px solid $layout-main;
    cursor: default;
    font-size: 1.1rem;
  }

  &-select-column {
    & > div {
      margin-left: 1rem;
    }
  }

  & tbody tr {
    border-bottom: 1px solid $layout-main;

    &:hover {
      background-color: $layout-dark-2;
      cursor: pointer;
    }

    &[draggable] {
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    & th,
    td {
      font-size: 0.9rem;
    }

    &_header i {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1100px) {
    & th,
    td {
      font-size: 0.7rem;
    }

    &_header i {
      font-size: 0.9rem;
    }
  }
}

.gsta-table-complete th.action {
  width: 10%;
  @media screen and (max-width: 1400px) {
    width: 14%;
  }
  @media screen and (max-width: 1100px) {
    width: 20%;
  }
}

.gsta-table-last-row {
  border-style: none !important;
}

.gsta-table-complete-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &-filter {
    background-color: $layout-dark;
    border: solid 1px $primary;
    border-radius: 1rem;
    padding: 1rem;
    z-index: 13;
    position: absolute;
    right: 0;
    top: 3rem;
    width: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 20rem;

    &::-webkit-scrollbar-track {
      margin: 1rem 0;
    }

    @media screen and (max-width: 1366px) {
      width: 220px;
    }

    @media screen and (max-width: 1024px) {
      width: 180px;
    }

    &-column-name {
      @media screen and (max-width: 1400px) {
        white-space: normal;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 0.5rem;
    }

    &_value {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      white-space: nowrap;
    }

    &_back {
      width: 100%;
      height: 100%;
      background-color: #00000000;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.gsta-table-row-no-border {
  border: none !important;
}

.gsta-table-complete-row-expanded-column {
  background-color: $layout-middle;
  border: none !important;
  box-shadow: inset 0 0 0.2em $layout-dark-shadow;
  visibility: hidden;
  opacity: 0%;
  position: relative;

  & .delta-loader {
    position: absolute;
    width: 5rem;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-container {
    visibility: visible;
    opacity: 100%;
    min-height: 10rem;
    transition: all 200ms ease-in;

    &-close {
      transition: all 200ms ease-out;
      opacity: 0%;
      visibility: hidden;
      min-height: 0;
      max-height: 0;
    }
  }

  &-is-open {
    transition: all 500ms ease-in;
    opacity: 100%;
    @extend .gsta-table-complete-row-expanded-column;
  }
}

.gsta-table-row_cell-name {
  display: flex;
  align-items: center;
  justify-content: center;

  & button {
    font-size: 0.7rem;

    @media screen and (max-width: 1400px) {
      font-size: 0.6rem;
    }

    @media screen and (max-width: 1100px) {
      font-size: 0.5rem;
    }
  }
}

.gsta-table-reset-filter {
  cursor: pointer;
  border: 1px solid $primary;
  color: $primary;
  border-radius: 0.2rem;
  padding: 0.5rem;
  margin: auto;
  font: inherit;
  background-color: transparent;
  width: 22%;
  overflow: auto;
  @media screen and (max-width: 1400px) {
    font-size: 0.85rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.75rem;
  }

  &:hover {
    background-color: $layout-dark-shadow;
    border: 1px solid $primary;
  }

  &_none {
    visibility: hidden;
  }
}

.gsta-table-action-button {
  cursor: pointer;
  border: 1px solid $primary;
  color: $primary;
  border-radius: 0.2rem;
  background-color: transparent;
  font-size: 1.2rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $layout-dark;
    border: 1px solid $primary;
  }

  &:disabled,
  &:disabled:hover &[disabled] {
    cursor: not-allowed;
    border: 1px solid $primary-dark;
    color: $primary-dark;
    background-color: transparent;
  }

  @media screen and (max-width: 1400px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.8rem;
  }
}

.gsta-table-no-result-container {
  background-color: $layout-dark;
  border: none !important;
  & span,
  & p {
    padding: 0;
    margin: 0;
    min-height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
