.gsta-span {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid $text-color-inversed;
  padding: 0.5rem;
  min-width: 5rem;
  cursor: default;
  position: relative;
  & > i {
    padding: 0 0.5rem 0 0;
  }

  & .icon-search {
    position: absolute;
    padding: unset;
    color: $text-color-disabled;
    right: -2px;
    top: 2px;
    font-size: 0.8rem
  }
  &-valid {
    @extend .gsta-span;
    background-color: $valid;
    color: $text-color-primary;
    border-color: $valid;
  }
  &-invalid {
    @extend .gsta-span;
    background-color: $invalid;
    color: $text-color-primary;
    border-color: $invalid;
  }
  &-in-progress {
    @extend .gsta-span;
    background-color: $layout-main;
    border-color: $layout-main;
    color: $primary;
    @media screen and (max-width: 1400px) {
      min-width: auto;
      font-size: 0.8rem;
    }
    @media screen and (max-width: 1100px) {
      min-width: auto;
      font-size: 0.7rem;
    }
  }
  &-disabled {
    @extend .gsta-span;
    background-color: $layout-main;
    border-color: $layout-main;
    color: $text-color-disabled;
  }
  &-outlined {
    @extend .gsta-span;
    background-color: transparent;
    border-color: $primary;
    color: $primary;
  }
  &-outlined-invalid {
    @extend .gsta-span;
    background-color: transparent;
    border-color: $invalid;
    color: $invalid;
  }
}
