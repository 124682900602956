@import "../../styles/variables/color";

.create-student_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h1 {
    font-size: 2rem;
  }

  & button {
    border: 2px solid $primary;
  }
}
