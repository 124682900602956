@import "../../styles//variables/color";

.create-pdf-template-step-one {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $text-color-disabled;
    & span {
      color: $text-color-disabled;
      text-align: right;
    }
  }



  .use-default {
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
    width: fit-content;
    .acreos-check-box {
      margin: 0;
    }
  }
}
