@import "../../styles/variables/color";

.gsta-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 3rem;
  position: relative;
  gap: 0.5rem;
  & > div,
  & > section > div {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    & input,
    & article {
      width: 100%;
    }
  }

  & label {
    width: 33%;
  }

  & article {
    width: 67%;
  }

  & input,
  textarea,
  select {
    background: $layout-dark;
    border-radius: 5px;
    padding: 1rem 4rem 1rem 1rem;
    width: 100%;
    height: 100%;
    font: inherit;
    color: inherit;
    border: none;
    border: 2px solid transparent;
    transition: all 0.3s;

    &:focus {
      border-color: $primary;
      outline: none !important;
    }

    &[invalid="true"] {
      border-color: $invalid;
    }
  }

  & i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
}

.gsta-input_column {
  & label {
    text-align: left;
    width: 100%;
  }
  & > div {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }
  & article {
    width: 100%;
  }
}

.gsta-input_row {
  flex-direction: row;
  gap: 0.5rem;
  & label {
    width: 15%;
  }
  & > section {
    width: 85%;
  }
}

.gsta-input--grey {
  margin-left: 1rem;
  & input {
    background: $layout-main;
    border-color: $layout-light;
    &:focus-within {
      border-color: $primary;
      background: $layout-main;
    }
  }
}

.gsta-input--black {
  padding-bottom: 0;
  & input,
  & select,
  & .acreos-select_selected {
    background: $layout-dark-shadow;
  }
}

.gsta-input-with-validation-button-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
  min-height: 3.5rem;
}

.gsta-select {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.gsta-input-border-dark:focus-within {
  border: 2px solid $primary;
  background-color: $layout-dark-shadow;
  outline: none !important;
}

.gsta-input-error,
.gsta-input-error:focus {
  border: 2px solid $invalid !important;
}

.gsta-input-error-message {
  top: 100%;
  padding: 0.5rem 0rem;
  color: $invalid;
  text-align: right;
  min-height: 2.1rem;
}

.gsta-input-error-message-absolute {
  @extend .gsta-input-error-message;
  position: absolute;
  left: 0;
  text-align: left;

  @media screen and (max-width: 1455px) {
    width: 41%;
  }

  @media screen and (max-width: 1270px) {
    width: 33%;
    font-size: 0.7rem;
  }
}

.gsta-input-error-light-background {
  color: $invalid;
}

.gsta-input input:disabled {
  -webkit-text-fill-color: $text-color-disable;
}

.gsta-input-with-validation-icon {
  display: flex;
  margin: 0;
  cursor: pointer;
}

.gsta-input-with-validation-container {
  display: flex;
  justify-content: flex-end;

  &-hidden {
    @extend .gsta-input-with-validation-container;
    visibility: hidden;
  }
}

.gsta-input-main-container {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
