@import "../../../styles/variables/color";

$text-active-color: $text-color-disabled;
$text-disabled-color: $text-color-inversed;

.side-connexion-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
  &.loading,
  &.loading input,
  &.loading i,
  &.loading button {
    cursor: wait !important;
  }

  & > form {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
.connexion-title {
  font-size: 23px;
  font-weight: bold;
}

.connexion-sub-title {
  font-size: 15px;
  color: #a7a7aa;
}

.login-logo {
  padding: 1rem 0;
  font-size: 7rem;
  color: $primary;
  &[visible="false"] {
    display: none;
  }
}

.connexion-button {
  color: $text-color-primary;
  border: 1px solid $text-color-primary;
  background-color: transparent;
  margin: 0 !important;
  &:hover {
    background-color: rgba($text-color-primary, 0.2);
  }

  &.active {
    border: 1px solid $primary-light;
    &:hover {
      background-color: $primary-light;
    }
  }

  &:disabled,
  &:disabled:hover {
    color: $text-color-disabled;
    background-color: rgba($text-color-disabled, 0.1);
    border-color: $text-color-disabled;
    cursor: not-allowed;
  }
  width: 100%;
}

.gsta-text-field {
  position: relative;
  padding: 1rem 0 0 0;
  width: 100%;
  & > input {
    font: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid $text-active-color;

    outline: 0;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
      color: transparent;
    }

    &:-webkit-autofill {
      &:placeholder-shown ~ .form__label {
        visibility: hidden;
      }
    }

    &:placeholder-shown ~ .form__label {
      cursor: text;
    }

    &:focus {
      color: $primary;
      border-color: $primary;
    }

    &:focus,
    &:not(:placeholder-shown) {
      ~ .form__label {
        position: absolute;
        top: 0;
        display: block;

        transition: 0.2s;
        font-size: 0.7rem;
        color: $primary;
      }
      padding-bottom: 6px;
      border-width: 3px;
      border-image-slice: 1;
      color: inherit;
    }

    &[valid="true"] {
      box-shadow: none;
      border-color: $invalid !important;
      ~ .form__label {
        color: $invalid;
      }
    }

    &:disabled,
    &:disabled:focus {
      cursor: not-allowed;
      color: $text-disabled-color;
      border-color: $text-disabled-color;
      ~ .form__label {
        color: $text-disabled-color;
      }
    }
  }
}
.show-password-icon {
  font-size: 1.2rem;
  cursor: pointer;
  position: absolute;
  top: 30%;
  right: 0;
  &:hover {
    color: $primary;
  }

  &.disabled {
    color: $text-disabled-color;
  }
}

.form__label {
  position: absolute;
  top: 25px;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $text-active-color;
}

.error-message {
  color: $invalid;
  display: inline-block;
  min-height: 1rem;
  width: 10rem;
  line-height: 1rem;
  margin: 5px 0 0;
}
