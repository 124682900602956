$primary: #fa9600;
$valid: #32936f;
$invalid: #e84b58;
$text-color-primary: #e9e9e9;
$text-color-secondary: #bdbfc2;
$background-dark: #232a35;
$background-medium: #393f49;
$border-radius-03rem: 0.3rem;

.acreos-span {
  padding: 0.5rem;
  color: $text-color-secondary;
  font-size: 1.2rem;
  margin: 0 1rem;
  background-color: $background-medium;
  border-radius: $border-radius-03rem;

  //décomposition pour une meilleure lecture des différents styles d'apparence.
  &.outlined {
    border: 1px solid $text-color-primary;
    color: $text-color-primary;
    background-color: $background-dark;

    &--orange {
      color: $primary;
      border-color: $primary;
    }

    &--success {
      background-color: $valid;
      color: $text-color-primary;
    }

    &--error {
      background-color: $invalid;
      color: $text-color-primary;
    }
  }

  &.orange {
    color: $primary;
  }

  &.success {
    background-color: $valid;
    color: $text-color-primary;
  }

  &.error {
    background-color: $invalid;
    color: $text-color-primary;
  }
}
