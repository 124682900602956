@import "../../styles/variables/color";

.image-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & > h2,
  h1 {
    margin: 0;
  }
  & > h2 {
    color: $text-color-disabled;
    font-size: 1rem;
  }

  & button:disabled,
  & button:disabled:hover {
    background-color: rgba(black, 0.3);
    color: $primary-dark;
    border-color: $primary-dark;
    cursor: not-allowed;
  }

  & input {
    visibility: collapse;
  }

  & > section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    margin: 0 1rem 0 0;
    & > .placeholder,
    img {
      border: orange solid;
      padding: 0.5rem;
      border-radius: 1rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.3s;
      &:hover {
        background-color: rgba(black, 0.3);
        cursor: pointer;
      }
    }

    & > div:first-child {
      & span{
        font-size: 10rem;
      }
    }
    & > div:last-child {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
  }
}
