$layout-dark-shadow: #191e27;
$text-color-disabled: #a09e9e;
$layout-middle: #393f49;
$primary: #fa9600;
$text-color-primary: #e9e9e9;

.grouped-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;

  &-mapped {
    display: flex;
  }

  & > section {
    display: flex;
    gap: 1rem;
    & > button {
      box-shadow: 2px 2px 5px 0 $layout-dark-shadow;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      padding: 0.4rem;
      align-items: center;
      color: $text-color-disabled;
      background-color: $layout-dark-shadow;
      border: solid 1px transparent;
      border-radius: 5px;
      font-weight: bold;
      cursor: pointer;

      & > i {
        visibility: hidden;
      }

      &:disabled {
        background-color: $layout-middle;
        border: solid 1px $layout-middle;
        cursor: default;
      }

      &:hover {
        background-color: $layout-middle;
        border: solid 1px $layout-middle;
      }

      &[buttonSelected="true"] {
        color: $primary;
        border-color: $primary;
        & > i {
          visibility: visible;
        }
      }
    }
  }
}

.radio-buttons {
  @extend .grouped-buttons;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  & > section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    & > button {
      border: none;
      background-color: transparent;
      box-shadow: none;
      color: $text-color-primary;
      position: relative;
      &:hover {
        border: none;
        background-color: transparent;
      }

      &:disabled{
        background-color: transparent;
        border: none;
        color: $text-color-disabled;
        cursor: not-allowed;
      }
     
      &::before{
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in;
        border-radius: 1rem;
        content: close-quote;
        height: 1rem;
        border: 2px solid $text-color-disabled;
        width: 1rem;
        -webkit-box-shadow: inset 0px 0px 0px 2px $layout-dark-shadow;
        -moz-box-shadow: inset 0px 0px 0px 2px $layout-dark-shadow;
        box-shadow: inset 0px 0px 0px 2px $layout-dark-shadow;
        
      }
      &[buttonselected="true"]::before{
        background-color: $primary;
      }
      &[buttonselected="true"]{
        color: $text-color-primary;
      }
      &[buttonselected="true"] > i {
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: visible;
        position: absolute;
        color: $layout-dark-shadow;
        font-size: 0.7rem;
        padding: 0 0 0 2px;
        opacity: 1;
      }
    }
  }
}

.grouped-button-label {
  color: $text-color-disabled;
  font-weight: bold;
}
