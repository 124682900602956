@import "../../../styles/variables/color";

.create-student-main-information-form {
  display: grid;
  grid-template-columns: 50% 50%;
  & .student-access-dates-pickers {
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    width: 100%;
    & > .acreos-date-picker_input {
      & > section {
        & > span {
          background-color: $layout-dark-shadow;
        }
      }
    }
    & > label {
      width: 33%;
      font-weight: bold;
      display: flex;
      justify-content: center;
    }
    & > section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      & > section {
        width: 100%;
        & > article > section > div {
          & > section > div {
            background-color: $layout-dark;
            border-color: $layout-dark;
          }
          &.error {
            & > section > div {
              border-color: $invalid;
            }
          }
        }
      }
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.create-student-mandatory {
  text-align: right;
}
