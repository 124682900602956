.module-name-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  gap: 1rem;
  padding: 1rem;
  & > span{
   width: 100%;
   text-align: center;
  }
  & > img {
    width: 7em;
  }
}
