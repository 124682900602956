@import "../../../styles/variables/color";

.skills-container {
  display: flex;
  flex-wrap: wrap;
}

.skill-title {
  font-weight: "bold";
  width: 100%;
  padding: 0.5rem 1rem;
}

.skill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding: 0.5rem 4rem;
  color: $text-color-disabled;
  flex-grow: 0;
  & span {
    width: 100%;
    word-break: break-all;
    padding-right: 10px;
  }
  @media screen and (max-width: 1000px) {
    flex-grow: 1;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.skill-placeholder {
  width: 100%;
  text-align: center;
}
