@import "../../../styles/variables/color";

.create-student-training-table {
  margin-bottom: 6rem;

  & tr, th {
    border-bottom: solid 1px $text-color-disable;
  }

  & tr:last-child {
    border: none;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & button {
      margin-right: 0;
    }
  }
}
