@import "../../../styles/variables/color";
@import "../../../styles/variables/layout";

.student-traininig-card {
  background-color: $layout-dark;
  padding: 1rem 0;
  margin: 1rem 0;
  width: 100%;
  border-radius: $border-radius-small;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
