@import "../../styles/variables/color";

.progress-bar {
  color: $primary;
  border: none;
  background-color: transparent;
  margin-top: 0.5rem;
  height: 0.5rem;
  width: 100%;
  &::-moz-progress-bar {
    background: $primary;
    border-radius: 0.5rem;
  }
  &::-webkit-progress-value {
    background: $primary;
    border-radius: 0.5rem;
  }
  &::-webkit-progress-bar {
    background: transparent;
  }
}
