@import "../../../styles/variables/color";

.student-actions-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  position: relative;

  & .export-pdf-options {
    margin-top: 2.5rem;
  }
}


