@import "../../styles/variables/color";

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  overscroll-behavior: none;
  background-color: rgba(1, 1, 1, 0.4);

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $layout-dark;
    overflow: auto;
    overscroll-behavior: contain;
    box-shadow: 2px 2px 5px 0 rgb(17, 17, 17);
    margin: auto;
    min-height: 25rem;
    max-height: 90%;
    border-radius: 1rem;
    min-width: 650px;
    width: 50%;
    overflow-y: scroll;

    &-table {
      @extend .modal-content;

      @media screen and (max-width: 1400px) {
        min-width: 1000px;
      }

      @media screen and (max-width: 1100px) {
        min-width: 900px;
      }

      min-width: 1500px;
    }

    @media screen and (max-width: 1400px) {
      max-width: 90%;
    }
  }

  &-content[isloading="true"] {
    justify-content: unset;
  }

  &-title {
    display: flex;
    align-items: center;
    border-bottom: 2px $layout-main solid;
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
    background-color: $layout-dark;
    z-index: 10;
    padding: 0 2rem !important;

    & i {
      font-size: 3rem;
      margin-right: 1.5rem;
    }

    @media screen and (max-width: 1400px) {
      font-size: medium;
    }

    @media screen and (max-width: 1100px) {
      font-size: small;
      margin-bottom: 0rem;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;

    & .gsta-table-shadow {
      box-shadow: none;
      width: 100%;

      & .gsta-table-complete tbody tr {
        height: 108px;
        cursor: default;

        & td {
          padding: 0.1rem 0.3rem;
          line-break: anywhere;
          white-space: break-spaces;
        }
      }

      & .text-icon {
        gap: 0.5rem;

        @media screen and (max-width: 1400px) {
          gap: 0.4rem;
        }

        @media screen and (max-width: 1100px) {
          gap: 0.2rem;
        }
      }
    }

    & .add-training-container {
      flex-direction: row;
    }
  }

  &-action {
    position: sticky;
    bottom: 0;
    display: flex;
    flex: row;
    justify-content: space-around;
    align-items: center;
    border-top: 2px $layout-main solid;
    margin: 2rem 0 0 0;
    padding: 1rem 0;
    height: auto;
    font-size: large;
    background-color: $layout-dark;
    z-index: 10;

    @media screen and (max-width: 1400px) {
      font-size: medium;
      height: 75px;
    }

    @media screen and (max-width: 1100px) {
      font-size: small;
    }

    &-button {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font: inherit;
      cursor: pointer;
      background-color: transparent;
      padding: 0.5rem;
      margin: 0.5rem;
      gap: 0.5rem;
      border-radius: 0.2rem;
      border: 1px solid $primary;

      &:disabled,
      &[disabled] {
        cursor: not-allowed;
        border: 1px solid $primary-dark;
      }

      &-cancel {
        @extend .modal-action-button;
        border: 1px solid $primary;
        color: $primary;

        &:hover {
          background-color: rgba($primary-dark, 0.2);
        }
      }

      &-validate {
        @extend .modal-action-button;
        background-color: $primary;
        color: $text-color-inversed;

        &:hover {
          background-color: $primary-dark;
          border: 1px solid $primary-dark;
        }

        &:disabled,
        &[disabled] {
          background-color: $primary-dark;
        }
      }
    }
  }

  & .gsta-table-pagination-container[firefox_compatibility="true"] {
    margin-bottom: 5rem;
  }
}

.modal-loader {
  display: flex;
  align-items: center;
  height: 73%;
  & .delta-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
    & svg {
      width: 13%;
    }
    & span {
      margin-top: 2rem;
    }
  }
}
