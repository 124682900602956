@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.login-info-chip {
  display: flex;
  align-items: center;
  background: $layout-dark-shadow;
  padding: 0.5rem;
  cursor: pointer;
  margin-right: 6px;

  &:hover {
    color: $primary;
  }
}

.chip-icon {
  font-size: 2rem;
}

.login-info-chip-text {
  white-space: nowrap;
  & i {
    margin-right: 0.2rem;
  }
}
