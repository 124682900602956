@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.chip-filter {
  display: flex;
  align-items: center;
  background-color: $layout-dark;
  color: $primary;
  border: 1px solid $primary;
  border-radius: $border-radius-small;
  padding: 0.5rem;
  margin: 0.3rem;
  font-size: 1rem;
  cursor: pointer;

  & i {
    cursor: pointer;
    margin-left: 0.5rem;
  }

  &:hover {
    background-color: $layout-dark-shadow;
  }
}
