@import "../../../styles/variables/color";

.create-custom-field-form {
  width: 100%;
  position: relative;

  & > article {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2rem;
    & > span {
      position: absolute;
      right: 0;
    }
  }
}
.custom-field--width {
  width: 70% !important;
}
.custom-field_input {
  align-items: flex-start;
  padding: 0;
  & label {
    text-align: start;
    width: 100%;
  }
  & article {
    width: 100%;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & input,
  & select {
    background: $layout-dark-shadow;
    &:focus {
      background: $layout-dark-shadow;
    }
  }
}
.checkbox-custom-filed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.custom-field_values {
  list-style-type: none;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
  border: solid 2px $layout-light;
  border-radius: 5px;
  background-color: $layout-middle;
  & > li {
    display: grid;
    grid-template-columns: 3.4rem 1fr 3.4rem 3.4rem;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    &.error {
      & > span {
        grid-column: 1 / 3;
        background-color: transparent;
        border: none;
        color: $invalid;
      }
    }
    & > span {
      height: 100%;
      background-color: $layout-dark-shadow;
      border: solid 2px $layout-light;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > button {
      width: 100%;
      height: 100%;
      background-color: $layout-middle;
      padding: 0;
      margin: 0;
      & > i {
        font-size: 1.5rem;
      }
    }
  }
}
