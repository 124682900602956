.create-student-training-table_column-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 1100px) {
    justify-content: center;
    flex-direction: column;
  }
}

.journey-image {
  max-width: 5rem;
}

.display-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
