@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.edit-pdf-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  & .lign-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: none;
    background: $layout-dark-shadow;
    padding: 1rem;
    font-family: inherit;
    box-shadow: 0 2px 4px $layout-dark;
    transition: all 0.3s;
    border-radius: $border-radius-small;
    &:hover {
      cursor: pointer;
      box-shadow: 0 4px 8px $layout-dark-shadow;
    }
  }
  & .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  & .input-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    & div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    & label {
      text-align: center;
    }
    & input {
      background-color: $layout-dark-shadow;
    }
  }
}
