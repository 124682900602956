@import "./variables/color";
@import "./variables/layout";
@import "./Common/common";
@import "./Common/table";
@import "./Common/keyframes";
@import "./Common/Animation/rotation";
@import "./Icon/style.css";
@import "./Common/button";
@import "./Common/span";
@import "./Components/delta-loader";
@import "./Common/Animation/transition";

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
 -webkit-box-shadow: 0 0 0 30px $layout-dark inset !important;
 -webkit-text-fill-color: $text-color-primary !important;
}

#root{
  overflow: hidden;
}
