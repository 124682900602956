@import "../../styles/variables/color";

.create-template_modal {
  & .modal-content {
    height: 78vh;
    width: 40vw;
    & .modal-main {
      gap: 1rem;
      height: 100%;
      justify-content: flex-start;
    }
  }
  & .back-button {
    padding: 0;
    margin: 0;
    width: 100%;
    color: $text-color-disable;
    &:hover{
     color: $text-color-primary;
    }
    & i {
     font-size: 1.5rem;
    }
    &.disabled{
     color: $text-color-inversed;
     &:hover{
      cursor: not-allowed;
     }
    }
  }
}
