@import "../../../styles/variables/color";
@import "../../../styles/Common/Animation/transition";

.cursus-step {
  display: flex;
  & > .step-timeline {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 calc(-1rem - 2px);

    & div:first-child {
      border-left: solid 4px $text-color-disable;
      height: 50%;
    }

    & div:last-child {
      border-left: solid 4px $text-color-disable;
      height: 50%;
    }
  }
}

.circle-centered {
  display: flex;
  align-items: center;

  & > div {
    position: relative;
    background-color: $layout-main;
    padding: 0.2rem 0 0.2rem 0;
  }
}
.step-info {
  background-color: $layout-dark;
  border-radius: 5px;
  padding: 1.5rem 3rem 0.5rem 1rem;
  flex: 0 1 100%;
  margin: 0.5rem 0 0.5rem 1.5rem;

  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-header-title {
    @extend .step-info-header;
    align-items: center;
    gap: 2rem;

    &-optionnal {
      background-color: $layout-middle;
      padding: 0.2rem 1rem 0.1rem 1rem;
    }

    & .switch-with-text[isvisible="true"] {
      @include transition(0.5s);
      opacity: 100;
    }

    & .switch-with-text[isvisible="false"] {
      @include transition(0.5s);
      opacity: 0;
    }
  }
  &-header-extra-title {
    @extend .step-info-header;
    gap: 2rem;
    color: $text-color-disable;
    margin: 0 1rem -1rem 0;
  }
  &-description {
    color: $text-color-disable;
  }
  & > p {
    height: 1px;
    background-color: $text-color-disable;
    margin: 1rem 0;
  }
}
