.patch-note-modal {
  & .modal-action {
    justify-content: space-between;
    & button {
      margin-left:2rem;
    }
  }

  & .modal-title {
    margin-top: 0.5rem;
  }
}
