@import "../../../styles/variables/color";

.acreos-link {
  color: $text-color-primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: $primary;
  }
}
