@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.acreos-date-picker {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &_year-span {
    font-size: 1.5rem;
    font-weight: bold;
  }

  & .gsta-date-picker {
    cursor: pointer;
    position: relative;
    border-radius: 0.5rem;
    background-color: $primary;
    color: $text-color-inversed;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 12rem;
    border: none;
    font: inherit;
    font-weight: bold;
    gap: 1rem;
    padding: 0.7rem 1rem;

    &:hover {
      background-color: $primary-light;
    }

    & i {
      font-size: 0.8rem;
    }

    &-icon-down {
      transform: rotate(-180deg);
    }
  }
  & > article.date-picker_container {
    min-width: 23.4rem;
    position: absolute;
    z-index: 100;
    top: 120%;
    right: 0;
    background-color: $layout-main;
    border: solid 1px $primary;
    border-radius: $border-radius-small;
    box-shadow: 0 4px 8px $layout-dark;
    & > h1 {
      font-size: 1.2rem;
      margin: 1rem 0.5rem 0.5rem 0.5rem;
    }
    & > article {
      padding: 1rem 1rem;
    }
    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font: inherit;
      font-weight: normal;
      color: $text-color-primary;
      gap: 0.5rem;
      border: none;
      background-color: transparent;
    }

    & > .change_date-select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      & > button {
        border: none;
        background-color: transparent;
        border-radius: 10rem;
        height: 1.5rem;
        width: 1.5rem;
        & i {
          position: relative;
          right: 0;
          bottom: 0;
        }
        &:hover {
          background-color: $layout-light;
        }
        &:disabled {
          color: $text-color-disabled;
          background-color: transparent;
          cursor: not-allowed;
        }
      }
      & > div {
        display: flex;
        & > button {
          background-color: $layout-dark;
          border: 1px $layout-light solid;
          padding: 0.5rem 1rem;
          &[active="true"] {
            border-color: $primary;
            & > i {
              transform: rotate(180deg);
              color: $primary;
            }
          }
          & > i {
            position: relative;
            bottom: 0;
            right: 0;
            transition: transform ease-in-out 0.2s;
            font-size: 0.5rem;
          }
        }
        & > button:first-child {
          border-top-left-radius: $border-radius-small;
          border-bottom-left-radius: $border-radius-small;
        }
        & > button:last-child {
          border-top-right-radius: $border-radius-small;
          border-bottom-right-radius: $border-radius-small;
        }
      }
    }

    & button.date_button {
      border-radius: $border-radius-small;
      padding: 1rem;
      &:hover {
        background-color: $layout-main;
      }
      &:disabled,
      &:disabled:hover {
        cursor: not-allowed;
        background-color: transparent;
        color: $text-color-disabled;
      }
      &[current="true"] {
        color: $primary;
        font-weight: bold;
      }
      &[active="true"] {
        background-color: $primary;
        color: $text-color-inversed;
        font-weight: bold;
      }
    }

    & > .month-year_grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      background-color: $layout-dark;
      border-bottom-left-radius: $border-radius-small;
      border-bottom-right-radius: $border-radius-small;
      width: 100%;
    }

    & .days_grid {
      display: grid;
      width: 100%;
      background-color: $layout-dark;
      grid-template-columns: repeat(7, 1fr);
      border-bottom-left-radius: $border-radius-small;
      border-bottom-right-radius: $border-radius-small;
      text-align: center;
      min-height: 20rem;
      & > span {
        margin-bottom: 1rem;
      }

      & .date_button {
        align-self: center;
        justify-self: center;
        padding: 0;
        border-radius: 5rem;
        height: 2rem;
        width: 2rem;
        margin: 1px;
        &[current_day="true"] {
          color: $primary;
          font-weight: bold;
        }
        &.middle {
          background-color: $primary-half-opacity;
          color: $text-color-primary;
          width: 100%;
          border-radius: 0;
        }
        &.single,
        &.first,
        &.last {
          position: relative;
          height: 2rem;
          width: 2rem;
          color: $text-color-inversed;
          font-weight: bold;
          & > span {
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            background-color: $primary;
            border-radius: 5rem;
            height: 2rem;
            width: 2rem;
          }
        }
        &.first,
        &.last {
          &::after {
            content: "";
            position: absolute;
            background-color: $primary-half-opacity;
            width: 100%;
            left: 55%;
            height: 100%;
          }
        }
        &.last {
          &::after {
            left: -55%;
          }
        }
        &.last.first {
          &::after {
            display: none;
          }
        }
        &[active="true"] {
          color: $text-color-inversed;
          background-color: $primary;
        }
      }
    }
  }

  & > article.date-picker_container.left {
    left: 0;
  }
}
