@import "../../../styles/variables/color";

.custom-field_actions {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: $text-color-disabled;
}

.not_selectable {
  user-select: none;
}
