@import "../../styles/variables/color";

.notifications-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 100;
  right: 0;
}

.notification {
  border: solid 2px $primary;
  margin: 0.2rem;
  background-color: $layout-dark;
  padding: 1rem 2rem;
  border-radius: 5px;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  position: relative;
  & > div {
    display: flex;
    flex-direction: column;
  }
  &-hidden {
    @extend .notification;
    visibility: hidden;
    opacity: 0;
  }
  &-success {
    border-color: $valid;
    &-icon {
      color: $valid;
    }
  }
  &-error {
    border-color: $invalid;
    &-icon {
      color: $invalid;
    }
  }
  &-valid {
    border-color: $valid;
  }
  &-quit {
    font-size: 0.7rem;
    padding: 0.2rem;
    color: $text-color-primary;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    &:hover {
      border-color: $primary;
      color: $primary;
      cursor: pointer;
    }
  }
}
