@import "../../styles/variables/color";
.patch-notes-content {
  & > div {
    display: flex;
    align-items: center;
    font-style: italic;
    margin-left: 1rem;
    & i {
      font-size: 2rem;
      margin-right: 0.5rem;
    }
  }
  & ul {
    margin: 0;
    margin-left: 3.5rem;
    padding: 0;
    position: relative;
    & > div {
      margin-bottom: 1rem;
    }
    & li {
      margin-bottom: 0.5rem;
      align-items: center;

      & i {
        position: absolute;
        left: -1.5rem;
        &.icon-check {
          color: $valid;
        }
      }

      &[label-icon="true"] i.icon-check {
        position: unset;
        margin-left: 0.5rem;
      }
    }
  }
}
