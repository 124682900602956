.skills-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & i {
    margin: 0rem 1rem;
  }

  & .tooltip {
    display: flex;
    flex-direction: column;
    & .tooltip-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & i {
        margin-left: unset;
      }
    }
  }
}
