@import "../../styles/variables/color";

.timeLine {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  & .step {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    text-decoration: none;

    &::after {
      content: "";
      display: block;
      width: 1.5rem;
      margin: 0 5px;
      border: 1px solid $text-color-disable;
      background-color: transparent;
      @media screen and (max-width: 1400px) {
        width: 1.3rem;
      }
      @media screen and (max-width: 1100px) {
        width: 1rem;
      }
    }
  }

  & .step:last-child::after {
    display: none;
  }

  &.lessStep::before,
  &.moreStep::after {
    content: "";
    width: 1.5rem;
    height: 2px;
    background-color: transparent;
    margin: 0 5px;
    border: 1px dashed $text-color-disable;
    @media screen and (max-width: 1400px) {
      width: 1.3rem;
    }
    @media screen and (max-width: 1100px) {
      width: 1rem;
    }
  }
}
