@import "../../styles/variables/color";

.import-students-modal-table_actions {
  display: flex;
  justify-content: center;
  align-items: center;
  & .gsta-button-outlined:disabled {
    border-color: $primary-dark;
    background-color: $layout-dark;
    color: $primary-dark;
  }
}
