@import "../../../styles/variables/color";

.training-step-item {
  display: flex;
  justify-content: center;
  &-image {
    display: flex;
  }
  &-value {
    align-items: center;
  }

  &-active {
    display: flex;
    justify-content: center;
    color: $primary;
  }
}
