@import "../../../styles/variables/color";

.modal-content:has(.student_grouped-action_container) {
  overflow: initial;
}

.student_grouped-action_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 50vh;
  & > h1 {
    color: $primary;
    font-size: 1.2rem;
    font-weight: normal;
    margin-bottom: 2rem;
  }
  & > div {
    width: 70%;
    display: flex;
    flex-direction: column;
    //gap: 2rem;
  }
  & .acreos-select_selected {
    height: 34px;
  }
  & input {
    border-color: $layout-light;
  }
}
