.delta-loader {
  width: 7em;
  margin-top: 2rem;
}
#delta-path {
  animation: fill 1s ease-in-out infinite;
  stroke: $primary;
}

@keyframes fill {
  from {
    stroke-dasharray: 0, 500;
  }
  to {
    opacity: 0.2;
    stroke-dasharray: 500, 500;
  }
}

#delta-path-3-1 {
  stroke: $primary;
  opacity: 0.3;
}
