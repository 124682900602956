.acreos-custom-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;

  & i {
    font-size: 2.5rem;
    margin: 1rem;
  }

  & span {
    font-weight: bold;
    margin-right: 1rem;
  }

  &.disabled {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.333);
  }
}