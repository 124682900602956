.student-machines {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  flex-wrap: wrap;
  & i {
    padding: 0 0.5rem;
    @media screen and (max-width: 1400px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 1100px) {
      font-size: 1.6rem;
    }
  }
}
