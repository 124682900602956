@import "../../styles/variables/color";

.modal.grouped-pdf-modal {
  & .modal-content {
    height: 100%;
    position: relative;
  }
  & .modal-main {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    & hr {
      margin-top: 3rem;
      border-color: $text-color-disabled;
    }
    & .acreos-tabs {
      & .mandatory-field {
        display: flex;
        justify-content: flex-end;
        font-weight: bold;
      }
      & .gsta-input {
        & input {
          background-color: $layout-dark-shadow;
          width: 60%;
        }
        & article {
          text-align: start;
        }
        margin-bottom: 2rem;
      }
      & .period-export {
        margin-bottom: 2rem;
        width: 45%;
      }

      & .session-dates {
        display: flex;
        flex-direction: column;
        margin: 0 0 2rem 3rem;
        color: $primary;
      }
      & .radio-buttons {
        display: flex;
        align-self: start;
        & label {
          font-weight: bold;
        }
        & button {
          font-weight: unset;
        }
      }
      & .session-dates-not-selectables {
        color: $primary-dark;
      }
      & .export-pdf-modal-list {
        display: flex;
        align-self: start;
        margin-top: 2rem;
      }
    }

    & .export-modal {
      margin-top: 2rem;
    }
  }

  & .modal-action {
    justify-content: space-between;
    & button {
      margin: 0 1.8rem 0 1.8rem;
    }
  }
}
