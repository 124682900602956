@import "../../../styles/variables/color";

.side-connnexion-form-footer {
  padding: 0 2rem;
  width: 100%;
  height: 13vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $layout-dark;
  @media screen and (max-width: 590px) {
    flex-direction: column;
  }

  & > section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > h1 {
      font-size: 1rem;
      padding: 0;
    }
    & > div {
      display: flex;
      gap: 2px;
    }
  }

  & > div:nth-child(1) {
    flex: 2 1;
    & > div {
      display: flex;
      justify-content: space-around;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    flex: 1 1;
    flex-direction: column-reverse;
    align-items: center;

    & .footer_img-acreos {
      height: 50%;
    }
  }
}

.footer-icon-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  margin: 5px;
  & > a {
    color: $text-color-disabled;
    font-size: 1.5rem;
    margin: 0 3px;
    display: flex;
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
    }
    &:hover {
      color: $primary;
    }
  }
}

.footer-contact-container {
  @extend .footer-icon-container;
  flex-direction: column;
  & > a {
    font-size: 0.8rem;
    padding: 0 5px 0.6rem 5px;
  }
}
.footer-powered-acreos {
  height: 5rem;
}
