@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.acreos-stepper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: $border-radius-small;
  border: 1px solid $layout-dark;
  background-color: $layout-dark;
  width: 100%;
  & > h1 {
    margin: 0;
    color: $text-color-disabled;
  }
  & > ul {
    display: flex;
    gap: 1rem;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
      width: 100%;
      height: 0.4rem;
      border-radius: $border-radius-small;
      &.clickable{
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}
.step-inactive {
  background-color: $layout-dark-shadow;
  border: 1px solid $layout-dark-shadow;
}
.step-active {
  background-color: $primary;
  border: 1px solid $primary-half-opacity;
}