@import "../../styles/variables/color";
@import "../../styles/variables/layout";

@mixin simulatorIsActive($boolean) {
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-left: 1rem;
  @if ($boolean) {
    background-color: $primary;
  } @else {
    background-color: $layout-dark-shadow;
  }
}

.cards {
  padding: 3rem 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: flex-start;
  gap: 2rem;
  @media screen and (max-width: 1400px) {
    gap: 1.4rem;
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 1200px) {
    gap: 0.8rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.card-item {
  border-radius: $border-radius-small;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $layout-dark;
  min-width: 15vw;
  max-width: 15vw;
  min-height: 200px;
  padding: 20px 15px;

  @media screen and (max-width: 1400px) {
    max-width: 25vw;
  }
  @media screen and (max-width: 1200px) {
    max-width: 40vw;
  }

  &_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding-bottom: 0.5rem;
  }
  &_description-inactivity {
    padding: 2rem;
    color: $text-color-disabled;
    text-align: center;
    font-weight: 600;
  }
  &_description-name {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  &_description-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &_circle-activity {
    @include simulatorIsActive(true);
  }
  &_circle-inactivity {
    @include simulatorIsActive(false);
  }

  & .acreos-button {
    gap: 0;
    padding: 0;
    width: 100%;

    & i {
      font-size: 1.5rem;
    }
  }

}

.card-item i {
  padding: 0.5rem;
}

.card-item_text {
  color: $text-color-disabled;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}
