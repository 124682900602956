@import "../../styles/variables/color";
@import "../../styles/Common/functions.scss";

$shapeCircle: "circle";
$shapeSquare: "square";

@mixin circleSize($size) {
  width: $size;
  height: $size;
}

.circle {
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  --size: 2rem;
  @include circleSize(var(--size));
  border-radius: 50%;

  @media screen and (max-width: 1400px) {
    --size: 1.8rem;
  }
  @media screen and (max-width: 1100px) {
    --size: 1.5rem;
  }

  &-optional {
    background-image: border($text-color-primary, true, $shapeCircle);
    background-color: $layout-dark;
  }
  &-skip {
    background-image: border($text-color-disable, true, $shapeCircle);
    background-color: $layout-dark;
  }
  &-mandatory {
    background-image: border($text-color-primary, false, $shapeCircle);
    background-color: $layout-dark;
  }
  &-success {
    border-color: $valid;
    background-color: $valid;
  }
  &-fail {
    border-color: $invalid;
    background-color: $invalid;
  }
  &-current {
    border-color: $primary;
    background-color: $primary;
  }

  &.active {
    border: $primary 2px solid;

    &::after {
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 10.3px 10px 0 10px;
      border-color: $primary transparent transparent transparent;
      transform: rotate(0deg);
      background-color: transparent;
      position: absolute;
      top: -18px;
    }
  }
}

.step-progress-icon {
  color: $text-color-primary;
  height: auto;
  &-skip {
    color: $text-color-disabled;
    width: 100% !important;
    height: auto !important;
  }
}

.step-progress-text {
  fill: $text-color-primary;
  font-weight: bold;
  text-anchor: middle;
  font-size: 50px;
  pointer-events: none;
  &-skip {
    @extend .step-progress-text;
    fill: $text-color-disabled;
  }
  &-dark {
    @extend .step-progress-text;
    fill: $layout-dark;
  }
}

.step-progress_tooltip {
  margin: 1rem 0 0 0;
  max-width: 20rem;
  min-width: 20rem;
  padding: 1rem;
  z-index: 100;
  color: $text-color-primary;

  &_text {
    display: flex;
    justify-content: space-between;
    margin: 3px 0;

    & > span:first-child {
      text-align: initial;
    }
    & > span:nth-child(2) {
      width: 20%;
      text-align: end;
    }
  }

  &_more-details {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-top: 10px;
    color: inherit;
  }

  &.step-progress_tooltip--success {
    background-image: border($text-color-primary, false, $shapeSquare);
    background-color: $valid;
  }
  &.step-progress_tooltip--fail {
    background-image: border($text-color-primary, false, $shapeSquare);
    background-color: $invalid;
  }
  &.step-progress_tooltip--current {
    background-image: border($text-color-primary, false, $shapeSquare);
    background-color: $primary;
    color: $layout-dark;
  }
  &.step-progress_tooltip--optionnal {
    background-image: border($text-color-primary, true, $shapeSquare);
    background-color: $layout-dark;
  }
  &.step-progress_tooltip--skip {
    background-image: border($text-color-disable, true, $shapeSquare);
    color: $text-color-disabled;
    background-color: $layout-dark;
  }
  &.step-progress_tooltip--mandatory {
    background-image: border($text-color-primary, false, $shapeSquare);
    background-color: $layout-dark;
  }
}
