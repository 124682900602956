@import "../../../styles/variables/color";

.access-dates-text-orange {
  color: $primary;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.access-dates-text-default {
  color: #e9e9e9;
  width: 100%;
  text-align: center;
}
