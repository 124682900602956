@import "../../styles/variables/color";

.session-tool-tip-content {
  & header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    & i {
      margin-right: 0.5rem;
    }
  }
  & article {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin-bottom: 3rem;
    & .acreos-span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      color: $text-color-primary;
      & i {
        margin-left: 0.5rem;
        font-size: 1.3rem;
      }
    }
  }
  & footer {
    display: flex;
    justify-content: flex-end;
    & span {
      width: 60%;
    }
  }
}
