* {
  font-family: "Roboto", sans-serif;
}

.firefox {
  & * {
    scrollbar-color: $primary $layout-main;
    scrollbar-width: thin;
  }
}

.chrome {
  & * {
    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      margin-left: 1rem;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 20px;
    }
  }
}

// surchargé les valeurs par defaut de la lib react tooltips
:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 1 !important;
}

html,
body,
#root {
  margin: 0;
  height: 100vh;
  width: 100%;
}

body {
  color: rgb(233, 233, 233);
  font-weight: 400;
  font-size: 1rem;
  background-color: $layout-main;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.full-width {
  width: 100%;
}

.text-icon {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.text-icon-machine {
  @extend .text-icon;

  & > i {
    font-size: 2rem;
  }
}

.title {
  display: flex;
  align-items: center;
  color: $text-color-primary;
  font-weight: bold;
}

.title1 {
  @extend .title;
  font-size: 2.5rem;
  margin: 0 0 1rem 0;
}

.title2 {
  @extend .title;
  font-size: 1.8rem;
  margin: 0;
}

.title3 {
  @extend .title;
  font-size: 1rem;
  margin: 0;
}

.icon-button {
  background-color: transparent;
  border: none;
  color: $text-color-primary;
  padding: 0.5rem;
  margin: 0.2rem;
  border-radius: 50%;
  transition: background-color ease-in-out 0.35s;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: rgba($layout-light, 0.3);
    border-radius: 50%;
    transition: background-color ease-in-out 0.2s;
  }

  &:disabled {
    color: $text-color-disabled;
    cursor: auto;
    background-color: transparent;
  }

  & .active {
    color: $primary;
  }

  &-filter-count {
    position: absolute;
    top: 0;
    font-weight: bold;
    height: 1rem;
    width: 1rem;
    background-color: $primary;
    border-radius: 50%;
  }
}

p,
h1,
h2,
h3,
h4 {
  cursor: default;
  color: $text-color-primary;
}

h1 {
  font-size: 1.4rem;
}

.color-orange {
  color: $primary;
}

.color-valid {
  color: $valid;
}

.color-invalid {
  color: $invalid;
}

.padding-bottom {
  padding-bottom: 1rem;
}

.margin-top {
  margin-top: 2rem;
}

.margin-left-right {
  margin-left: 2rem;
  margin-right: 2rem;
}

.dark-shadow-bg {
  background-color: $layout-dark-shadow;
}

.display-flex {
  display: flex;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}
