@import "../../../styles/variables/color";
@import "../../../styles/variables/layout";

.result-per-training {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.5s;
  min-height: 175.2px;
  position: relative;
  border-radius: $border-radius-small;
  background-color: $layout-dark;

  &_steps {
    width: 100%;
    padding-left: 0;

    &_title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
      width: 100%;
      padding: 1rem;

      & h1 {
        font-size: 1.1rem;
        font-weight: bold;
      }

    }

    &_container {
      padding: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.span-title {
  font-size: 0.8rem;
}

.student-training--separator {
  border-bottom: 2px solid $text-color-disabled;
  margin: 0 2rem;
}

.title-part {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.title-part-last {
  flex-grow: 2;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  > div:nth-child(-n+2) {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-right: 2px solid $text-color-disabled;
    padding-right: 1rem;
    margin-left: 0.5rem;
  }

  & > span {
    margin-left: 0.6rem;
  }
}


