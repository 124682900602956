@import "../../styles/variables/color";

.sim-tooltip-content {
  color: $text-color-primary;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  & ul,
  li {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  & ul {
    margin-top: 0.5rem;
  }

  & li {
    margin-left: 1.1rem;
    margin-bottom: 0.2rem;
    font-style: italic;
  }

  & span {
    font-weight: bold;
  }
}
