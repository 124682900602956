@import "../../../styles/variables/color";

.gsta-input-mail-with-warning {
  display: flex;
  align-items: flex-start;

  & > div:first-child {
    width: 50%;
    margin-right: 0.5rem;
  }
}

.warning-message {
  & > i {
    font-size: 1.5rem;
    padding: 0 1rem 0 0;
    color: $primary;
  }
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $layout-dark;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 2px solid $primary;
  &-hidden {
    visibility: hidden;
  }
}
