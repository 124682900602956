@import "../../../styles/variables/color";
@import "../../../styles/variables/layout";

.global-info {
  display: flex;
  flex-direction: column;
  background-color: $layout-dark;
  border-radius: 5px;
  padding: 1rem 2rem;
  color: $text-color-disable;
  gap: 1rem;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
      @extend .global-info-header;
      gap: 4rem;
      @media (max-width: $screen-width-medium) {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
      }
    }

    &-info {
      @extend .global-info-header-left;
      @media (max-width: $screen-width-medium) {
        flex-direction: row;
        gap: 4rem;
      }
      @media (max-width: $screen-width-small) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
      }
    }

    &-machine {
      color: $text-color-disabled;
    }
  }

  & > p {
    height: 1px;
    background-color: $text-color-disable;
    margin: 0;
  }

  &-description {
    display: flex;
    align-items: center;
    text-overflow: clip;
    gap: 2rem;
    margin-bottom: 2rem;
  }

  &-skills {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    & > * {
      margin: 0.5rem 1rem 0.5rem 0;
      //margin: 0.5rem 0;
    }
    @media (max-width: $screen-width-medium) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $screen-width-small) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
