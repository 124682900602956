$text-color-disabled: #a09e9e;
$layout-main: #393f49;

.create-value-footer {
  position: fixed;
  bottom: 0;
  width: 83%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px $text-color-disabled;
  background-color: $layout-main;
  & button {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  & > div {
    display: flex;
    align-items: center;
    & > span {
      margin-right: 2rem;
    }
  }
}
