@import "../../../styles/variables/color";

.student-detail-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.student-detail-table {
  display: grid;
  grid-template-columns: 10% 15% 18% 17% 40%;
  background-color: $layout-dark-2;
}

.cell-container {
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  hyphens: auto;
  word-break: break-word;

  @media screen and (max-width: 1100px) {
    padding: 0.5rem;
    & p {
      line-break: anywhere;
    }
  }
}

.student-detail {
  &-container {
    cursor: default;
    background-color: $layout-dark-1;
    display: flex;
    padding: 1rem;
    justify-content: space-around;
    align-content: center;
    position: relative;
  }

  &-container-no-content {
    cursor: default;
    background-color: $layout-dark-1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
  }

  &-machine-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $layout-main;
    border-radius: 5px;
    background-color: $layout-dark-2;
    min-width: 115px;

    & > i {
      font-size: 5rem;
    }
  }

  &-training-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin: 0 1rem;
    border-radius: 5px;
    flex-grow: 10;
    border: 1px solid $layout-main;
    background-color: $layout-dark-2;

    &-header {
      @extend .student-detail-table;
      font-weight: bold;
    }
    &-row {
      @extend .student-detail-table;
      border-top: 1px $layout-main solid;
    }
  }
}

.engine-name {
  line-break: normal;
  width: 6rem;
  margin-bottom: 1rem;
}
