@import "../../styles/variables/color";

.questionnary-modal {
  & .questionnary-modal-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    & .gsta-input-label {
      margin: 1rem 0;
    }
  }

  & .gsta-input {
    & .gsta-input-error-message {
      padding: 0;
      min-height: 0;
    }
  }

  & .hotline-infos {
    color: $primary;
    font-style: italic;
    display: inline-block;
    margin-bottom: 1rem;
    text-align: left;
  }

  & .required-field {
    color: $text-color-disabled;
    text-align: right;
    margin-top: 1rem;
  }

  & textarea {
    background-color: $layout-dark-shadow;
    height: 15rem;
  }

  & .modal-action {
    margin: 0 2rem 0 2rem;
  }

  & .required-field {
    color: white;
  }
}

.questionnary-footer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-right: 1.5rem;

  & span {
    font-size: smaller;
  }
}
