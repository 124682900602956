@import "../../styles/variables/color";

.pie-chart-notation {
  & .label--value {
    font-size: 2.2rem;
    fill: $text-color-primary;
    font-weight: bold;
  }
  & .label--text {
    font-size: 1.2rem;
    fill: $text-color-primary;
  }
}

.pie-chart-notation.in-progress {
  & .label--value {
    fill: $primary-light;
  }
}

.pie-chart-notation.not-started {
  & .label--value {
    fill: $text-color-disabled;
    font-size: 1.6rem;
  }
}

.pie-chart-notation.in-waiting {
  & .label--value {
    fill: $primary-light;
  }
}
