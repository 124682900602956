@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.pdf-template-resume {
  border: 3px solid $layout-light;
  border-radius: $border-radius-small;
  & h1 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.5rem;
    margin: 1rem;
    & i {
      font-size: 1.5rem;
    }
  }
  & > div {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
    border-bottom: 3px solid $layout-light;
  }
  & button {
    border: none;
  }
  & ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0;
    padding: 0.5rem 2rem;
    list-style-type: none;
    & > li {
      display: flex;
      gap: 0.5rem;
      & > i {
        color: $primary;
      }
    }
  }
}
