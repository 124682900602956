// color
$primary: #FA9600;
$layout-dark: #232A35;
$text-color-primary: #E9E9E9;
//



.acreos-check-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $text-color-primary;
    margin: 0.5rem;
    border-radius: 0.2rem;
    width: 1.2rem;
    height: 1.2rem;
    background-color: $layout-dark;
    cursor: pointer;
    position: relative;

    &[disabled]{
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.checked {
    border-color: $primary;
    background-color: $primary;
    color: $layout-dark;

    & i {
        font-size: 0.9rem;
    }
}