@import "../../../styles/variables/color";

.simulator-notation,
.modules-authorization_content {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  &-container {
    color: $text-color-disable;
    font-weight: bold;
    & > span {
      margin-right: 1.5rem;
    }
  }
  & label {
    font-weight: bold;
  }
}
