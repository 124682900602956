@import "../../../styles/variables/color";
@import "../../../styles/variables/layout";

.center-simulator-table-title {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: $layout-dark;
  border-top-left-radius: $border-radius-small;
  border-top-right-radius: $border-radius-small;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  transition: all 200ms ease-in;

  &[table-open="false"] {
    border-radius: $border-radius-small;
  }

  & > h2 {
    color: $title-blue;
    padding-right: 1rem;
    margin-left: 0.5rem;
    border-right: solid $text-color-disabled;
    display: flex;
    gap: 1rem;
  }

  &-arrow {
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    margin-right: 0.5rem;
  }

  &-pin {
    border-left: solid $text-color-disabled;
    display: flex;
    align-items: center;
    margin-top: 0.15rem;
    height: 37px;

    &-button {
      width: 34px;
      height: 34px;
    }

    & > span {
      margin-left: 1rem;
    }

    & > i {
      margin-left: 1rem;
    }
  }
}

.simulator-count-chip {
  display: flex;
  text-align: center;
  align-items: center;
  margin: 1rem;
  padding: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: $layout-main;
  border-radius: 2rem;

  & > span {
    margin-left: 1rem;
  }

  &-simulator-icon {
    margin-bottom: 0.4rem;
  }
}

.open-table-button {
  &[animation="1"] {
    -webkit-animation: rotation0 0.5s forwards;
    animation: rotation0 0.5s forwards;
  }

  &[animation="0"] {
    -webkit-animation: rotation180 0.5s forwards;
    animation: rotation180 0.5s forwards;
  }

  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

@keyframes rotation0 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes rotation180 {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}
