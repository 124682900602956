@import "../../../styles/variables/color";
@import "../../../styles/variables/layout";

.training-step-name-item {
  font-weight: bold;
  margin: 0.8rem;
  width: 100%;
  & figure {
    margin: auto;
    position: relative;
    & img {
      border: 2px solid grey;
      border-radius: $border-radius-3px;
      filter: brightness(70%);
    }
    & i {
      width: 4rem;
      height: 4rem;
      position: absolute;
      font-size: 4rem;
      color: $text-color-disabled;
      opacity: 0.7;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.training-step-name-item-image {
  width: 10em;
  margin: 0.5em;
  font-weight: normal;
}
