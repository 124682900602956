.timeline-vertical {
  margin: 0 1rem 0 0;
  padding: 0.5rem;

  & > div:first-child > div > div:nth-child(2) div:first-child {
    visibility: hidden;
  }
  & > div:last-child > div > div:nth-child(2) div:last-child {
    visibility: hidden;
  }
}
