@import "../../styles/variables/color";

.switch-with-text {
  display: flex;
  gap: 1rem;
  align-items: center;

  & > label {
    &[disable="true"] {
      color: $text-color-disabled;
    }
  }
}

.switch-with-text.center {
  justify-content: center;
}

.switch {
  position: relative;
  display: inline-block;
  min-width: 90px;
  min-height: 34px;
  vertical-align: middle;
  color: $text-color-primary;
  & input {
    display: none;
  }

  @media screen and (max-width: 1400px) {
    min-width: 75px;
    min-height: 28px;
  }
}

.slider {
  box-shadow: 1px 1px 5px 0 $layout-dark-shadow;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $layout-main;
  -webkit-transition: 0.4s;
  transition: 0.4s;

 

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: $layout-dark;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    @media screen and (max-width: 1400px) {
      height: 24px;
      width: 24px;
      left: 2px;
      bottom: 2px;
    }
  }
  &-dark {
    background-color: $layout-dark;
    font-weight: bold;
    color: $text-color-disabled;
    @extend .slider;
    &:before {
      background-color: $layout-dark-shadow;
    }
  }
}

input:checked + .slider {
  background-color: $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
  background-color: $text-color-primary;

  @media screen and (max-width: 1400px) {
    -webkit-transform: translateX(46px);
    -ms-transform: translateX(46px);
    transform: translateX(46px);
  }
}

@mixin on-off {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}

.off {
  @include on-off;
  color: $text-color-disabled;
  left: 70%;
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.5s;
  &.hide {
    transition: opacity 0.5s;
    opacity: 0;
  }
}

.on {
  @include on-off;
  color: $text-color-inversed;
  left: 30%;
  transition: opacity 0.5s;
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}

.slider.round {
  border-radius: 2rem;
  &[disable="true"] {
    cursor: not-allowed !important;
  }
}

.slider.round:before {
  border-radius: 2rem;
}
