.custom-training-roadmap {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  margin-bottom: 8rem;
  & table {
    border-radius: 1rem;
    overflow: hidden;
    & .switch-with-text {
      display: unset;
    }
  }
  & thead {
    & span {
      padding: 1rem;
    }
  }
  & > section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #a09e9e;
    & > div {
      display: flex;
      flex-direction: column;
      & > h1 {
        margin: 0.5rem 0;
      }
      & > div {
        display: flex;
        justify-content: space-between;
        & .icon-timer {
          margin-left: 1rem;
        }
        & > span {
          display: flex;
          gap: 0.5rem;
        }
      }
    }
  }
}
