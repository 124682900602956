@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.acreos-date-picker_input {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 3rem;
  position: relative;
  gap: 0.5rem;
  & > section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    & input {
      &:focus {
        outline: none;
      }
      color: inherit;
      font: inherit;
      border: none;
      width: 100%;
      background-color: transparent;
      padding: 0;
    }
    & > div {
      &::after {
        position: absolute;
        content: attr(data-placeholder);
        pointer-events: none;
        opacity: 0.6;
        white-space: pre;
      }
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: $layout-dark-shadow;
      padding: 1rem;
      border-radius: $border-radius-small;
      border: 2px solid $layout-light;

      &:hover {
        cursor: pointer;
        & i {
          color: $primary;
        }
      }

      & i[date-picker-open="true"] {
        color: $primary;
      }
    }
  }
  &_error-message {
    width: 100%;
    text-align: right;
    min-height: 1.5rem;
    padding: 0;
    color: $invalid;
  }

  &.enabled {
    & > section {
      & > span {
        border-color: $primary;
      }
    }
  }

  &.error {
    & > section {
      & > div {
        border-color: $invalid;
      }
    }
  }
}

.acreos-date-picker_input_row {
  & > section {
    flex-direction: row;
    text-align: center;
    align-items: center;
    & > label {
      width: 33%;
    }
  }
}

.acreos-date-picker_input_grey {
  & > section {
    & > div {
      background-color: $layout-dark;
      border-color: $layout-dark;
    }
  }
}
