.patty {
  display: flex;
  justify-content: center;
  & img {
    height: 7rem;
  }

  &:hover {
    animation: 2s tada infinite;
  }
}
