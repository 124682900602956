@import "../../../styles/variables/color";
@import "../../../styles/variables/layout";

.student-personnal-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 2rem;
  padding-top: 0;
  margin-bottom: 3rem;
  background-color: transparent;
  border-radius: 2px;

  b {
    white-space: nowrap;
  }

  & h2 {
    @media screen and (max-width: 1050px) {
      margin-left: 2rem;
    }
  }

  @media screen and (max-width: 1100px) {
    padding-left: 0;
  }

  &-container {
    :hover {
      background-color: $layout-dark-shadow;
    }
    @media screen and (min-width: 1101px) and (max-width: 1780px) {
      padding-left: 0;
    }
  }

  &-container-calendar {
    margin: 2rem 0;
    border-radius: $border-radius-small;
    padding: 0 1rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    &[isloading="true"] {
      @media screen and (max-width: 1400px) {
        height: 50vh;
      }
    }

    @media screen and (min-width: 1400px) {
      width: 50%;
    }
  }

  &-calendar {
    padding: 1rem;
    background-color: $layout-dark;
    border-radius: $border-radius-small;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    position: relative;

    & .delta-loader {
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);

      @media screen and (min-width: 1400px) {
        top: 45%;
      }
    }
    &-datepicker {
      display: flex;
      justify-content: flex-end;
      & .acreos-date-picker {
        width: inherit;
      }
    }

    &-legend {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: center;
      justify-content: flex-start;
      background-color: $layout-dark;
      width: 100%;
      padding-left: 2rem;
      padding-bottom: 2rem;

      & div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
      }
      & .legend-detail {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $text-color-disabled;
        margin-right: 1.5rem;
        & span {
          cursor: default;
        }
      }

      & .legend-icon {
        width: 10px;
        height: 10px;
        margin-right: 1rem;
      }
    }
  }

  &-profil {
    margin: 2rem;
    padding: 0 1rem;

    @media screen and (min-width: 1400px) {
      width: 40%;
      height: 100%;
      margin: 2rem 0;
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
      height: 100%;
      margin: 2rem;
    }

    &-identity {
      padding: 2rem;
      background-color: $layout-dark;
      padding-bottom: 2.5rem;

      border-radius: $border-radius-small;

      &-password {
        color: $text-color-disabled;
      }

      & > section {
        display: flex;
        justify-content: space-between;
        & .color-disabled {
          color: $text-color-disabled;
        }
      }

      &-label {
        font-weight: 600;
      }

      &-input {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;
        color: $text-color-disabled;

        & > div {
          width: 100%;
          margin: 0;
        }
        &-container {
          padding: 1rem;
        }

        &-buttons {
          display: flex;
          width: 100%;

          &-validate-cancel {
            display: flex;
            flex-grow: 2;
            justify-content: flex-end;

            &-hidden {
              display: flex;
              visibility: hidden;
            }
          }

          & .gsta-input-error-message{
            padding: 0;
          }
        }

        &-pin {
          display: flex;
          height: 3rem;
          align-items: center;
          justify-content: space-between;
          margin-top: 0.5rem;
          cursor: pointer;
          border-radius: 3px;

          &:hover > i {
            color: $primary;
          }

          &-clicked {
            @extend .student-personnal-info-profil-identity-input-pin;
            border: 2px solid;
            border-color: $primary;
            &:hover > i {
              color: $text-color-disabled;
            }
          }

          &-label {
            margin-left: 1rem;
            border: none;
            color: $text-color-disabled;
            background: $layout-dark-shadow;
            font: inherit;
            cursor: pointer;
            -moz-appearance: textfield;
          }

          &-label:focus {
            outline: none;
          }

          &-label::-webkit-outer-spin-button,
          &-label::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }

        &-icon {
          margin-right: 1rem;
          cursor: pointer;
        }

        &-icon:hover {
          color: $primary;
        }

        @media screen and (max-width: 1050px) {
          flex-direction: column;
        }
      }
    }
  }
}

.student-calendar-and-legend {
  min-height: 650px;
}

.modificate-pin-span {
  margin-left: 0.5rem;
}

.modificate-pin-container {
  display: flex;
  justify-content: flex-end;
}

.error-length-pin {
  border: 2px solid #e84b58;
}

.student-access-dates-pickers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  & > .acreos-date-picker_range {
    width: 100%;
    & > article {
      & > .acreos-date-picker {
        padding: 0;
        & > .acreos-date-picker_input {
          & > section {
            & > span {
              & > .icon-calendar_month {
                padding-left: 2rem;
              }
            }
            & > div {
              border-radius: 5px;
              border: 2px solid transparent;
            }
          }
        }
      }
    }
  }
}
