@import "../../styles/variables/color";

.questionnary-chip {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  color: $text-color-primary;
  font-size: 2rem;
  width: 4.5rem;
  height: 70%;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }
  &:hover {
    color: $primary;
    & .tooltip-text {
      color: $text-color-primary;
    }
  }
}
