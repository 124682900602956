@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.icon-expand_more.dropdown {
  display: inline-block;
  margin-left: 0.5rem;
  transform: rotate(-180deg);
  transition: all 0.2s linear;
}

.icon-expand_more.dropup {
  display: inline-block;
  margin-left: 0.5rem;
  transition: all 0.2s linear;
}

.actions-grouped {
  position: relative;
  margin-bottom: 1rem;
}

.actions-grouped_list {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s linear;
  cursor: auto;
  position: absolute;
  z-index: 2;
  background-color: $layout-main;
  min-width: max-content;
  left: 0;
  top: 36px;
  border: 1px solid $primary;
  color: $text-color-primary;
  margin: 0;
  padding: 0.3rem;
  border-radius: $border-radius-3px;

  &.active {
    transition: all 0.5s linear;
    margin-top: 10px;
    display: flex;
    visibility: visible;
    flex-direction: column;
    opacity: 1;
  }

  & i {
    font-size: 1.2rem;
  }

  & :first-child {
    border-top-left-radius: $border-radius-3px;
    border-top-right-radius: $border-radius-3px;
  }

  & :last-child {
    border-bottom-left-radius: $border-radius-3px;
    border-bottom-right-radius: $border-radius-3px;
  }

  & li {
    padding: 0.5rem;
    list-style-type: none;
    display: flex;
    font-size: small;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $layout-light;
    }

    & > span {
      padding: 0 0 0 0.5rem;
    }
  }
}
