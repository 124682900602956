@import "../../styles//variables/color";

.gsta-table-pagination-container {
  color: $text-color-disabled;
  display: flex;
  justify-content: space-between;
  background-color: $layout-dark;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 1rem;
  

  @supports (-moz-appearance: none) {
    select {
      padding: 0.8rem 0.2rem;
    }
  }

  @media screen and (max-width: 1400px) {
    font-size: 0.9rem;
    padding: 0.9rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.8rem;
    padding: 0.8rem;
  }

  & .gsta-input-dark {
    @media screen and (max-width: 1400px) {
      min-height: 2rem;
      font-size: 0.9rem;
      padding: 0.4rem;
    }

    @media screen and (max-width: 1100px) {
      font-size: 0.8rem;
      min-height: 1.5rem;
      padding: 0.3rem;
    }
  }
  & .acreos-select {
    width: auto;
    & .acreos-select_selected {
      background-color: $layout-dark-shadow;
      border: none;
      width: 4rem;

      & .acreos-select_options {
        top: -8rem;
        border-bottom: none;
        border-color: $layout-dark-shadow;
        box-shadow: none;
        width: inherit;
      }
    }
  }
}

.gsta-table-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  &-select-element-per-page {
    @extend .gsta-table-pagination;

    & > .gsta-input--black {
      padding: 0 0 0 0.5rem;
    }

    & > div {
      padding-right: 1rem;
      flex-grow: 1;
    }

    & > span {
      margin-right: 0.3rem;
    }
  }

  &-select-page {
    @extend .gsta-table-pagination;

    & button {
      width: 2.5rem;
      height: 2.5rem;

      @media screen and (max-width: 1100px) {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
