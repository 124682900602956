@import "../../styles/variables/color";

.training-detail-info {
  margin: 2rem 2rem;

  & .training-detail-title{
    display: flex;
    justify-content: space-between;

    & div {
      display: flex;
      align-items: center;
      gap: 1rem;
      & span {
        color: $primary;
        font-size: 1.2rem;
        font-style: italic;
      }
    }
    & button {
      background-color: $layout-dark;
    }

  }
}
