@import "../../styles/variables/color";

.create-custom-pdf-step4 {
 display: flex;
 flex-direction: column;
 gap: 1rem;
 width: 100%;
  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $layout-light;
    & span {
      color: $text-color-disabled;
      text-align: right;
    }
  }
}
