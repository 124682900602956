@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.export-modal {
  display: flex;
  width: 100%;
  flex-direction: flex-start;
  & > section {
    width: 60%;
    @media (max-width: 1250px) {
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  & h3 {
    font-size: inherit;
    font-weight: bold;
  }
  & .pdf-template {
    align-items: flex-start;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: left;
      & > div {
        border: 2px solid $text-color-disable;
        margin-left: 0.4rem;
        background-color: $layout-dark-shadow;
        color: $text-color-disable;
      }
    }
  }
}
