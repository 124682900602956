@import "../../styles/variables/color";

.redirection-link {
  display: flex;
  justify-content: right;
  color: $text-color-disabled;
  & span {
    cursor: pointer;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}