@font-face {
  font-family: 'acreos_icons';
  src:  url('fonts/acreos_icons.eot?rh8uty');
  src:  url('fonts/acreos_icons.eot?rh8uty#iefix') format('embedded-opentype'),
    url('fonts/acreos_icons.ttf?rh8uty') format('truetype'),
    url('fonts/acreos_icons.woff?rh8uty') format('woff'),
    url('fonts/acreos_icons.svg?rh8uty#acreos_icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'acreos_icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-feedback:before {
  content: "\e900";
}
.icon-volume_up:before {
  content: "\e901";
}
.icon-volume_down:before {
  content: "\e902";
}
.icon-draft:before {
  content: "\e903";
}
.icon-check_box:before {
  content: "\e904";
}
.icon-remove:before {
  content: "\e905";
}
.icon-add_circle:before {
  content: "\e906";
}
.icon-disabled_by_default:before {
  content: "\e907";
}
.icon-add:before {
  content: "\e908";
}
.icon-action_video:before {
  content: "\e909";
}
.icon-action_replay:before {
  content: "\e90a";
}
.icon-cached:before {
  content: "\e90b";
}
.icon-Action_fast_forward:before {
  content: "\e90c";
}
.icon-info:before {
  content: "\e90d";
}
.icon-action_replace:before {
  content: "\e90e";
}
.icon-bar_chart_4_bars:before {
  content: "\e90f";
}
.icon-CHI:before {
  content: "\e910";
}
.icon-checklist:before {
  content: "\e911";
}
.icon-user_attributes:before {
  content: "\e912";
}
.icon-no_journey:before {
  content: "\e913";
}
.icon-north:before {
  content: "\e914";
}
.icon-down_long_arrow:before {
  content: "\e915";
}
.icon-filter_alt:before {
  content: "\e916";
}
.icon-keyboard_double_arrow_left:before {
  content: "\e917";
}
.icon-keyboard_double_arrow_right:before {
  content: "\e918";
}
.icon-keyboard_arrow_left:before {
  content: "\e919";
}
.icon-keyboard_arrow_right:before {
  content: "\e91a";
}
.icon-D1B_Picto:before {
  content: "\e91b";
}
.icon-hourglass:before {
  content: "\e91c";
}
.icon-Gauge:before {
  content: "\e91d";
}
.icon-note_add:before {
  content: "\e91e";
}
.icon-person_add:before {
  content: "\e91f";
}
.icon-east:before {
  content: "\e920";
}
.icon-west:before {
  content: "\e921";
}
.icon-bulldozer:before {
  content: "\e922";
}
.icon-calibration:before {
  content: "\e923";
}
.icon-location_on:before {
  content: "\e924";
}
.icon-check:before {
  content: "\e925";
}
.icon-circle_fill_off:before {
  content: "\e926";
}
.icon-circle_fill_on:before {
  content: "\e927";
}
.icon-close:before {
  content: "\e928";
}
.icon-home:before {
  content: "\e929";
}
.icon-backspace:before {
  content: "\e92a";
}
.icon-dynamic:before {
  content: "\e92b";
}
.icon-pencil:before {
  content: "\e92c";
}
.icon-expand_more:before {
  content: "\e92d";
}
.icon-visibility:before {
  content: "\e92e";
}
.icon-visibility_off:before {
  content: "\e92f";
}
.icon-cancel:before {
  content: "\e930";
}
.icon-plagiarism:before {
  content: "\e931";
}
.icon-conversion_path:before {
  content: "\e932";
}
.icon-goal:before {
  content: "\e933";
}
.icon-signal_cellular_alt:before {
  content: "\e934";
}
.icon-waving_hand:before {
  content: "\e935";
}
.icon-key:before {
  content: "\e936";
}
.icon-language:before {
  content: "\e937";
}
.icon-action_video1:before {
  content: "\e938";
}
.icon-list:before {
  content: "\e939";
}
.icon-lock:before {
  content: "\e93a";
}
.icon-login:before {
  content: "\e93b";
}
.icon-logout_person1:before {
  content: "\e93c";
}
.icon-more:before {
  content: "\e93d";
}
.icon-arrow_circle_left:before {
  content: "\e93e";
}
.icon-pdf:before {
  content: "\e93f";
}
.icon-person:before {
  content: "\e940";
}
.icon-school:before {
  content: "\e941";
}
.icon-search:before {
  content: "\e942";
}
.icon-shutdown:before {
  content: "\e943";
}
.icon-action_fast_forward:before {
  content: "\e944";
}
.icon-star_fill_on:before {
  content: "\e945";
}
.icon-check_circle:before {
  content: "\e946";
}
.icon-trophy:before {
  content: "\e947";
}
.icon-timer:before {
  content: "\e948";
}
.icon-touch_finger:before {
  content: "\e949";
}
.icon-trafic-light:before {
  content: "\e94a";
}
.icon-trophy1:before {
  content: "\e94b";
}
.icon-undo:before {
  content: "\e94c";
}
.icon-lock_open:before {
  content: "\e94d";
}
.icon-task_alt:before {
  content: "\e94e";
}
.icon-BKL:before {
  content: "\e94f";
}
.icon-BKS:before {
  content: "\e950";
}
.icon-BU2:before {
  content: "\e951";
}
.icon-BUL:before {
  content: "\e952";
}
.icon-BUS:before {
  content: "\e953";
}
.icon-CA2:before {
  content: "\e954";
}
.icon-CAM:before {
  content: "\e955";
}
.icon-CAV:before {
  content: "\e956";
}
.icon-CH2:before {
  content: "\e957";
}
.icon-CHA:before {
  content: "\e958";
}
.icon-CHL:before {
  content: "\e959";
}
.icon-CHM:before {
  content: "\e95a";
}
.icon-CHT:before {
  content: "\e95b";
}
.icon-ENJ:before {
  content: "\e95c";
}
.icon-FAF:before {
  content: "\e95d";
}
.icon-GM2:before {
  content: "\e95e";
}
.icon-GMP:before {
  content: "\e95f";
}
.icon-GT2:before {
  content: "\e960";
}
.icon-GTC:before {
  content: "\e961";
}
.icon-HDF:before {
  content: "\e962";
}
.icon-MAG:before {
  content: "\e963";
}
.icon-NIV:before {
  content: "\e964";
}
.icon-PAC:before {
  content: "\e965";
}
.icon-PAP:before {
  content: "\e966";
}
.icon-PC2:before {
  content: "\e967";
}
.icon-PCL:before {
  content: "\e968";
}
.icon-PCM:before {
  content: "\e969";
}
.icon-PL1:before {
  content: "\e96a";
}
.icon-POR:before {
  content: "\e96b";
}
.icon-PVR:before {
  content: "\e96c";
}
.icon-RST:before {
  content: "\e96d";
}
.icon-RTG:before {
  content: "\e96e";
}
.icon-SDT:before {
  content: "\e96f";
}
.icon-TAG:before {
  content: "\e970";
}
.icon-TO2:before {
  content: "\e971";
}
.icon-TOA:before {
  content: "\e972";
}
.icon-TRA:before {
  content: "\e973";
}
.icon-TRP:before {
  content: "\e974";
}
.icon-VL1:before {
  content: "\e975";
}
.icon-star_fill_off:before {
  content: "\e976";
}
.icon-expand_less:before {
  content: "\e978";
}
.icon-mail:before {
  content: "\e979";
}
.icon-phone:before {
  content: "\e97a";
}
.icon-quick_reference:before {
  content: "\e97b";
}
.icon-settings:before {
  content: "\e97c";
}
.icon-play_arrow:before {
  content: "\e97d";
}
.icon-Logo_GSTA:before {
  content: "\e97e";
}
.icon-logout:before {
  content: "\e97f";
}
.icon-flag:before {
  content: "\e980";
}
.icon-sound_off:before {
  content: "\e981";
}
.icon-monitor:before {
  content: "\e982";
}
.icon-switch:before {
  content: "\e984";
}
.icon-text:before {
  content: "\e985";
}
.icon-list_field:before {
  content: "\e986";
}
.icon-calendar_month:before {
  content: "\e987";
}
.icon-error:before {
  content: "\e989";
}
.icon-arrow_circle_right:before {
  content: "\e98a";
}
.icon-action_fast_forward1:before {
  content: "\e98b";
}
.icon-restart:before {
  content: "\e98c";
}
.icon-network:before {
  content: "\e98d";
}
.icon-download:before {
  content: "\e98f";
}
.icon-tools:before {
  content: "\e990";
}
.icon-upload_file:before {
  content: "\e991";
}
.icon-CHP:before {
  content: "\e992";
}
.icon-TO3:before {
  content: "\e993";
}
.icon-CP0:before {
  content: "\e994";
}
.icon-MA0:before {
  content: "\e995";
}
.icon-PC0:before {
  content: "\e996";
}
.icon-TA0:before {
  content: "\e997";
}
.icon-3T5:before {
  content: "\e998";
}
.icon-AGR:before {
  content: "\e999";
}
.icon-BCE:before {
  content: "\e99a";
}
.icon-BEN:before {
  content: "\e99b";
}
.icon-BTP:before {
  content: "\e99c";
}
.icon-MULTI:before {
  content: "\e99d";
}
.icon-PRT:before {
  content: "\e99e";
}
.icon-SYL:before {
  content: "\e99f";
}
.icon-file_copy:before {
  content: "\e9a0";
}
.icon-unarchive:before {
  content: "\e9a1";
}
.icon-delete:before {
  content: "\e9a2";
}
.icon-facebook:before {
  content: "\e9a3";
}
.icon-instagram:before {
  content: "\e9a4";
}
.icon-website:before {
  content: "\e9a5";
}
.icon-linkedin:before {
  content: "\e9a6";
}
.icon-youtube:before {
  content: "\e9a7";
}
.icon-warning:before {
  content: "\e9a8";
}
.icon-archive:before {
  content: "\e9a9";
}
.icon-CH0:before {
  content: "\e9aa";
}
.icon-TO0:before {
  content: "\e9ab";
}
.icon-fast_rewind:before {
  content: "\e9ac";
}
.icon-head_mounted_device:before {
  content: "\e9ad";
}
.icon-CA5:before {
  content: "\e9ae";
}
.icon-CA3:before {
  content: "\e9af";
}
.icon-notifications:before {
  content: "\e9b2";
}
.icon-new:before {
  content: "\e9b3";
}
.icon-upgrade:before {
  content: "\e9b4";
}
.icon-Action_DroneView:before {
  content: "\e9b5";
}
.icon-groups:before {
  content: "\e9b6";
}
.icon-unknown_document:before {
  content: "\e9b7";
}
.icon-help:before {
  content: "\e9b8";
}
