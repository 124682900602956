@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.create-pdf-template-step2-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  & header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $text-color-disabled;
    & span {
      color: $text-color-disabled;
      text-align: right;
    }
  }
  & .radio-buttons {
    margin: 0;
  }
  & h3 {
    margin: 0;
  }
}
