$header-height: 6rem;

$medium: 750px;
$large: 1080px;
$xlarge: 1440px;

$screen-width-small: 1024px;
$screen-width-medium: 1366px;
$screen-width-large: 1920px;
$screen-height-small: 640px;
$screen-height-medium: 768px;
$screen-height-large: 1080px;

$navigation-width: 15%;
$layout-width: calc(100% - $navigation-width);
$navigation-width-medium: 20%;
$layout-width-medium: calc(100% - $navigation-width-medium);
$navigation-width-large: 13%;
$layout-width-large: calc(100% - $navigation-width-large);

$title-height: 5rem;
$border-radius-small: 0.5rem;
$border-radius-3px: 3px;

@mixin navigation-width {
  width: $navigation-width;
  @media screen and (max-width: $medium) {
    width: $navigation-width-medium;
  }
  @media screen and (min-width: $large) {
    width: $navigation-width-large;
  }
}

@mixin layout-margin {
  margin-left: $navigation-width;
  @media screen and (max-width: $medium) {
    margin-left: $navigation-width-medium;
  }
  @media screen and (min-width: $large) {
    margin-left: $navigation-width-large;
  }
}

@mixin header-width {
  width: calc(100% - $navigation-width);
  @media screen and (max-width: $medium) {
    width: calc(100% - $navigation-width-medium);
  }
  @media screen and (min-width: $large) {
    width: calc(100% - $navigation-width-large);
  }
}

@mixin layout-width {
  width: $layout-width;
  @media screen and (max-width: $medium) {
    width: $layout-width-medium;
  }
  @media screen and (min-width: $large) {
    width: $layout-width-large;
  }
}

.logout-button {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: $text-color-primary;
  text-decoration: none;
  font-weight: bold;
  gap: 0.5rem;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: $layout-dark;
  }

  &.active {
    background-color: $primary;
    color: $text-color-inversed;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 15%;
      height: 1px;
      width: 70%;
      border-bottom: 1px solid $layout-dark-shadow;
      z-index: 6;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0%;
      left: 15%;
      height: 1px;
      width: 70%;
      border-bottom: 1px solid $primary;
      z-index: 6;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 15%;
    height: 1px;
    width: 70%;
    border-bottom: 1px solid $text-color-disabled;
    z-index: 5;
  }
}

.gsta-sub-navigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $text-color-disabled;

  &-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-color-primary;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    background-color: transparent;
    padding: 1rem;
    font-size: 1.1rem;
    position: relative;
    &.active {
      color: $primary;
      &::after {
        content: "";
        bottom: calc(0% - 1px);
        width: 100%;
        height: 2px;
        position: absolute;
        border-bottom: 2px solid $primary;
      }
    }
  }
}
