@import "../../../styles/variables/color";
@import "../../../styles/variables/layout";
@mixin gsta-box-shadow {
  box-shadow: 2px 2px 5px 0 $layout-dark-shadow;
}

.manage-inscription {
  margin-bottom: 3rem;
  & > header {
    padding: 0.5rem 1rem;
    border-top-left-radius: $border-radius-small;
    border-top-right-radius: $border-radius-small;
    width: 100%;
    background-color: $layout-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include gsta-box-shadow;
    margin-top: 2rem;

    & > h1 {
      margin: 0;
      padding: 0;
      & span {
        margin-left: 0.5rem;
      }
    }
  }
}

.manage-date-access {
  padding: 0.5rem 1rem;
  border-top-left-radius: $border-radius-small;
  border-top-right-radius: $border-radius-small;
  width: 100%;
  background-color: $layout-dark;
  display: block;
  justify-content: space-between;
  align-items: center;
  @include gsta-box-shadow;

  & h1 {
    padding-bottom: 1rem;
    border-bottom: solid 2px white;
  }
}

.custom-field_table {
  margin: 0.3rem 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-shadow: 2px 2px 5px 0 $layout-dark-shadow;
  & th {
    padding: 1rem 0;
  }
  & tr{
    height: 3rem;
  }
}
