@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.simulators-activity div .cards {
  padding-top: 3rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: flex-start;
  gap: 2rem;
  @media screen and (max-width: 1400px) {
    gap: 1.4rem;
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 1200px) {
    gap: 0.8rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
