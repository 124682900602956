#profile {
  margin-left: 2rem;
  margin-top: 2rem;

  & .profile_account {
    & > div {
      width: 50%;
    }
  }
}

.hr-without-margin-top {
  margin-top: 0;
  width: 100%;
}
