.table-container[table-opened="false"] {
    display: none;
}

.gsta-table {
  border-radius: $border-radius-small;
  margin-bottom: 1rem;
  background-color: $layout-dark;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  & th,
  td {
    padding: 1rem 0.5rem;
  }
  &-empty {
    @extend .gsta-table;
    & th,
    td {
      border-bottom: none;
    }
  }
}


.gsta-table.gsta-table-complete tbody tr{
  border-bottom: solid 1px #777777;
}

.gsta-table th {
  font-weight: bold;
  font-size: 1.1rem;
}

.gsta-table tr:last-child > td:first-child {
  border-bottom-left-radius: 1rem;
}

.gsta-table tr:last-child > td:last-child {
  border-bottom-right-radius: 1rem;
}
