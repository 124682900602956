@import "../../styles/variables/color";

.black-screen {
 position: fixed;
 top: 0;
 left: 0;
 width: 100vw;
 height: 100vh;
 background-color: rgba($layout-dark, 0.7);
 z-index: 99;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 &.opaque{
  background-color: rgba($layout-dark, 1);
 }
}