#profile {
  & .profile_information .name {
    display: flex;
    width: 50%;
    justify-content: space-between;

    & > div {
      width: 48%;
    }

    @media screen and (max-width: 1300px) {
      flex-direction: column;
      width: 100%;

      & > div {
        width: 50%;
      }
    }
  }
}
