@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.card-total_content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_label {
    margin-left: 1em;
    font-size: small;
    font-weight: bold;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & section {
      display: flex;
      gap: 6px;
    }
  }
}

.card-total_label {
  font-size: small;
  font-weight: bold;
  margin-top: 1em;
  text-align: center;
}
