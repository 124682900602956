@import "../../../styles/variables/color";

@mixin vertical-bar {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0.95rem;
  height: 100%;
  border-left: 2px solid $text-color-primary;
}

@mixin horizontal-bar {
  content: "";
  display: inline-block;
  width: 2.1rem;
  height: 0.2rem;
  border-top: 2px solid $text-color-primary;
}

.machine-hierarchie {
  position: relative;
  width: 100%;
  padding: 2rem 0;


  & .result-per-training_steps.free {
    background-color: $layout-dark;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;

    & > span {
    font-weight: bold;
    padding: 1rem 1rem 1rem 0.5rem;
    }

    & .title-part-last > div {
      border: unset;
    }
  }

  & .result-per-machine + ul {
    list-style-type: none;
    text-indent: 0rem;
    padding-left: 0.5rem;
    margin: 0;
    position: relative;

    & > li {
      padding-left: 1.1rem;
      padding-bottom: 1rem;
      display: flex;
      align-items: center;
      position: relative;

      &:nth-child(n) {
        padding-top: 1rem;
        &:before {
          @include horizontal-bar;
        }
      }
      &::after {
        @include vertical-bar;
      }
      &:last-child {
        &::after {
          @include vertical-bar;
          height: 50%;
        }
      }
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
    padding-left: 0.3rem;

    &::before {
      @include vertical-bar;
      top: 3rem;
      height: calc(100% - 2rem);
      left: 1.45rem;
    }
  }
}
