@import "../../../styles/variables/color";

.side-unexpected-error {
  display: flex;
  width: 30%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    & span {
      color: $primary;
      cursor: pointer;
    }
  }
}

.login-logo {
  padding: 1rem 0;
  font-size: 7rem;
  color: $primary;
  &[visible="false"] {
    display: none;
  }
}
