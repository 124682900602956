@import "../../../styles/variables/color";

.training-see-more {
  color: $primary;

  & span {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
    columns: $primary-dark;
  }
}
