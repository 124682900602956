@import "../../../styles/variables/color";
@import "../../../styles/variables/layout";

.create-student-footer {
  display: flex;
  border-top: 1px solid $text-color-disabled;
  align-items: stretch;
  justify-content: space-between;
  width: 83%;
}

.double-button-container {
  display: flex;
  justify-content: center;
  align-items: center;

  &_label {
    margin-right: 1rem;

    &[add-another-student="true"] {
      color: $primary;
    }
  }
}

.bottom-fixed {
  position: fixed;
  background-color: $layout-main;
  bottom: 0;
}

.bottom-margin {
  margin-bottom: $title-height;
}
