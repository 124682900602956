#profile {
  & .profils_security {
    & > div {
      width: 50%;
    }
    & .full-width {
      width: 100%;
    }
  }
}

.display-flex-end {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}
