@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.global-layout {
  & .version {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    font-size: x-small;
  }

  & > nav {
    position: fixed;
    @include navigation-width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $layout-dark-shadow;
    box-shadow: 2px 2px 5px 0 $layout-dark-shadow;
  }

  & > header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    box-shadow: 2px 2px 5px 0 $layout-dark-shadow;
    background-color: $layout-dark-shadow;
    height: $header-height;
    @include layout-margin;
    @include header-width;
    z-index: 3;
    & h1 {
      margin-left: 2rem;
    }

    & > section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div:not(:last-child) {
        border-right: 1px solid $text-color-disabled;
      }
    }
  }

  & > section {
    height: 91vh;
    overflow-y: scroll;
    @include layout-margin;
    @include layout-width;
  }
}

.gsta-navigation {
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
  overflow: hidden;
  border-bottom: 1px $text-color-disabled solid;
  font-size: 1.2rem;

  & li {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;

    &:hover:not(.active) {
      cursor: pointer;
    }
  }

  & .active {
    color: $primary;
    border-bottom: 2px $primary solid;
    cursor: default;
  }
}

.new-gsta-navigation {
  height: 100vh;
}

.gsta-navigation-logo {
  min-height: $header-height;
  color: $primary;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 5rem;
}

.navigation-bar-powered-by-acreos {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 4rem;
}

.navigation-button-icon {
  font-size: 1.7rem;
}
