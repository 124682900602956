.gsta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  border-radius: 0.2rem;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.5rem;
  gap: 0.5rem;

  & > span {
    padding: 0;
  }

  &-fill {
    @extend .gsta-button;
    background-color: $primary;
    border: 1px solid $primary;
    color: $text-color-inversed;
    box-shadow: 1px 1px 10px 0px $primary inset;

    &:active {
      box-shadow: 0px 0px 5px 1px $layout-dark inset;
      border-color: $layout-dark;
    }

    &:hover {
      background-color: $primary-dark;
      border: 1px solid $primary-dark;
    }

    &:disabled,
    &:disabled:hover {
      background-color: rgba($primary-dark, 0.7);
      border: 1px solid transparent;
      cursor: not-allowed;
    }
    &.start-button{
      color: white;
      font-size: 2rem;
    }
  }

  &-outlined {
    @extend .gsta-button;
    align-items: center;
    color: $primary;
    border: 1px solid $primary;
    background-color: transparent;

    & > span {
      margin: 0;
    }

    &:hover {
      background-color: rgba($primary-dark, 0.2);
    }

    &:disabled,
    &:disabled:hover {
      background-color: rgba($primary-dark, 0.7);
      border: 1px solid $primary;
      cursor: not-allowed;
    }
  }

  &-container {
    display: flex;
    justify-content: center;
  }

  &.outline {
    background-color: $layout-dark;
    color: $text-color-disabled;
    border-color: $text-color-disabled;
    border-radius: $border-radius-3px;
    box-shadow: none;
  }
}

.left-icon > i {
  margin-right: 0.5rem;
}

.button--nav {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  cursor: pointer;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  color: $text-color-primary;

  &:active {
    background-color: $text-color-primary;
    opacity: 0.2;
    border-radius: 50%;
    color: $layout-dark;
  }

  &:disabled,
  :active:disabled {
    color: $text-color-disabled;
    background-color: transparent;
    cursor: default;
    opacity: 1;
  }
}
