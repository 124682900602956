@import "../../styles/variables/color";

.gsta-notice-chip {
  height: 70%;
  display: flex;
  align-items: center;
}

.gsta-notice-button {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: $text-color-primary;
  border: none;
  font-size: 2rem;
  width: 4.5rem;
  height: 70%;

  &.actions-grouped {
    margin: 0;
  }

  & .actions-grouped_list {
    margin-top: 35px;

    & li i {
      font-size: 1.2rem;
    }
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &:hover {
    color: $primary;
    & .tooltip-text {
      color: $text-color-primary;
    }
  }

  &.active {
    color: $primary;
  }
}
