.import-student-modal-step-three_title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    margin-right: 1.1rem;
    font-weight: bold;
  }
}
