@import "../../styles/variables/color";

// -- vars
$default-size: 6em;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .active-progress-bar {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

@mixin draw-progress--solid($progress, $color) {
  background: -moz-linear-gradient(right, $color 50%);
  background: linear-gradient(to right, $color 50%);
  &:before {
    @if $progress <= 50 {
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $color;
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
}

// -- selectors

*,
*:before,
*:after {
  box-sizing: border-box;
}

.charts-container {
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}

.circular-progress-bar {
  @include size($default-size, $default-size);
  background-color: $layout-dark;
  position: relative;
  &:nth-child(3n + 1) {
    clear: both;
  }

  .active-progress-bar {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, calc($default-size / 2));
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      @include size(100%, 100%);
      border: calc($default-size / 10) solid;
      border-radius: 50%;
      clip: rect(0, calc($default-size / 2), $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .inactive-progress-bar {
    @include size(100%, 100%);
    border: calc($default-size / 10) solid $layout-dark-shadow;
    border-radius: 50%;
  }

  @for $i from 1 through 100 {
    &.progress-#{$i} {
      @include draw-progress($i, $primary);
    }
  }
}
