@import "../../../styles/variables/color";
@import "../../../styles/variables/layout";

.result-per-training_steps_details {
  &.loading {
    justify-content: center;
    align-items: center;
    height: 150px;
  }
  background-color: $layout-dark;
  border-radius: 0 0 $border-radius-small $border-radius-small;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  transition: all 2s ease-in;

  & header {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    border-bottom: 2px solid $text-color-disabled;
    align-items: center;

    & h1 {
      font-size: 1.3rem;
    }

    & .icon-expand_less.dropup {
      transform: rotate(90deg);
    }

    & button {
      padding: 0 0.5rem;
      height: 2rem;
      & i {
        font-size: 0.7rem;
      }
      &:hover,
      :active {
        border-color: $primary;
        background-color: $layout-dark-light;
        color: $primary;
      }
      &:active {
        box-shadow: 0px 0px 5px 2px $primary-light inset;
      }
    }
  }

  & nav {
    margin: 1rem 0;
    border: 1px solid;
    padding: 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: $border-radius-small;
    background-color: $layout-dark-shadow;

    & > p {
      margin: 0;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
    }
  }

  &_total-time {
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div:nth-child(1) {
      display: flex;
      flex-direction: column;
    }

    & > .acreos-span {
      height: 80%;

      & i {
        color: $primary;
      }
      
      & div {
        display: flex;
        flex-direction: column;
      }
    }

    & .acreos-span {
      display: flex;
      gap: 0.5rem;
      width: max-content;
      margin: 0;
      align-items: center;
      font-size: 1rem;
    }
    
  }

  &_charts {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  & .gsta-table-shadow {
    box-shadow: none;
    & .gsta-table-search-bar-filter {
      min-height: auto;
      padding: 0;
      height: auto;
    }
  }

  & .gsta-table-complete {
    border-top: 2px solid $layout-main;
    & th {
      padding: 1.4rem 0.3rem;
      font-size: 1.2rem;
    }
    & td {
      padding: 1.1rem 0;
      font-size: 1rem;
    }
    & tr:hover {
      background-color: $layout-dark;
      cursor: auto;
    }
  }
}
