@import "../../../styles/variables/color";

.modal-footer-container {
  display: flex;
  justify-content: space-around;
  & p {
    color: $primary;
    margin: 1rem;
  }
}
