@import "../../../styles/variables/color";

.archived-unarchived-students_modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;
  & > span:last-child {
    color: $primary;
  }
}

.student-grouped-actions-container {
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
}

.archived-unarchived-students_modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;
  & > span:last-child {
    color: $primary;
  }
}

.grouped-action-text {
  text-align: center;
  font-size: small;
  color: $primary;
}

.switch-on {
  color: $primary;
}

.switch-off {
  transform: rotate(180deg);
}
