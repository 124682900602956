.training-step-machine-item {
  display: flex;
  justify-content: center;

  &-img {
    width: 3em;
  }
  &-value {
    margin-left: 0.5em;
  }
}
