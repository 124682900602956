$text-color-disabled: #a09e9e;

.student-page {
  margin: 0rem 2rem 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > header {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
      flex-direction: column;
    }

    & h1 {
      margin-bottom: unset;
    }
  }

  & .redirection-link {
    margin-top: 0.5rem;
  }
  
}

.create-student-button {
  padding: 0.5rem !important;
  min-width: 15rem;
  & > span {
    font-weight: bold;
  }
  & > i {
    font-size: 1.5rem;
  }
}
