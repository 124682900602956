@import "../../styles/variables/color";

.back-button {
  width: fit-content;
  padding: 1rem;
  display: flex;
  align-items: center;
  color: $text-color-primary;
  gap: 0.5rem;
  margin: 1rem 0;
  font-size: 1.1rem;
  cursor: pointer;

  & button {
    background-color: transparent;
    border: 0;
    color: inherit;
    font-size: 2.2rem;
    cursor: inherit;
    display: flex;
    justify-content: center;
  }

  &:hover {
    color: $text-color-disabled;
  }
}
