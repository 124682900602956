@import "../../styles/variables/color";

.connexion-layout {
  height: auto;
  max-height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-position: center;
  &_sideform {
    height: 100%;
    width: 35%;
    position: relative;
    background-color: $layout-dark;
    padding: 0;
    box-shadow: -4px 0px 10px 2px $layout-dark-shadow;
    @media screen and (max-width: 1100px) {
      width: 80%;
    }
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
}

.connexion-layout .redirect-to-gsta {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  border-radius: 0.2rem;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.5rem;
  gap: 0.5rem;
  background-color: transparent;
  position: absolute;
  color: $text-color-primary;
  border: 1px solid $text-color-primary;
  right: 0;

  &:hover {
    background-color: rgba($text-color-primary, 0.2);
  }

  & i {
    font-size: 1.5rem;
    color: $primary;
  }
}

.connexion-layout .redirect-to-gsta span {
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.connexion-layout .version {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  font-size: x-small;
}
