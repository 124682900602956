@import "../../../styles/variables/color";

.card {
  background-color: $layout-dark;
  box-shadow: 0 2px 4px $layout-dark;
  padding: 1rem;
  border-radius: 0.2rem;
  text-align: center;
  margin: 1rem;

  &-not-selected {
    @extend .card;
  }

  &-to-change {
    @extend .card;
    border: 1px $primary solid;
    padding: calc(1rem - 1px);
    box-shadow: 0 4px 8px $layout-dark;
  }

  &-selected {
    @extend .card;
    border: 1px $primary solid;
    padding: calc(1rem - 1px);
    box-shadow: 0 4px 8px $layout-dark;
  }

  &-current {
    @extend .card;
    color: $primary;
    border: 1px $primary solid;
    padding: calc(1rem - 1px);
    box-shadow: 0 4px 8px $layout-dark;
  }

  &-disabled {
    @extend .card;
    color: $text-color-disabled;
    background-color: $layout-dark-light;
  }
}

.card:hover {
  box-shadow: 0 8px 16px $layout-dark;
  cursor: pointer;
}

.card-not-selected:hover {
  border: 1px $text-color-primary solid;
  padding: calc(1rem - 1px);
  box-shadow: 0 8px 16px $layout-dark;
}

.card-disabled:hover {
  box-shadow: 0 2px 4px $layout-dark;
  cursor: not-allowed;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & span,
  div {
    padding: 0.5rem 0;
  }
}

.card-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
}

.center-card {
  min-width: 25%;
}

.center-icon {
  font-size: 200%;
}
