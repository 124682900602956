$primary: #fa9600;
$layout-main: #393f49;
$layout-dark: #232a35;
$layout-dark-shadow: #191e27;
$layout-middle: #31363f;
$text-color-disabled: #a09e9e;

.select-serie-exercice {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $layout-dark;
  border-bottom: 1px solid $layout-main;
  & > div {
    display: flex;
    justify-content: center;
    border: 2px solid $layout-main;
    width: 80%;
    height: 100%;
    margin: 1rem;
    & > section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #393f49;
        width: 100%;
        padding: 1rem 0;
        & > span {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          background-color: $layout-dark-shadow;
          padding: 0.5rem 2rem;
        }
      }

      & > ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        & > li > article {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: solid 1px $layout-main;
          gap: 1rem;
          padding: 2px 0.5rem 2px 1rem;
          & i:hover {
            cursor: pointer;
            color: $primary;
          }
          & > i {
            font-size: 2rem;
          }
        }
      }

      & > article {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: solid 1px $layout-main;
        gap: 1rem;
        padding: 2px 0.5rem 2px 1rem;
        & > i {
          color: $primary;
          transform: rotate(90deg);
          &:hover {
            cursor: pointer;
          }
        }
      }

      & > article.unselect-serie {
        display: flex;
        justify-content: stretch;
        flex-direction: row;
        border: none;
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: solid 1px $layout-main;
          gap: 1rem;
          padding: 1rem;
          height: 100%;
          position: relative;
          min-height: calc(94px + 2rem);
          & > i {
            position: absolute;
            top: 1rem;
            font-size: 2rem;
            color: $primary;
            &:hover {
              cursor: pointer;
            }
          }
        }
        & > ul {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          list-style-type: none;
          padding: 0;
          margin: 0;
          & > li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: solid 1px $layout-main;
            gap: 1rem;
            padding: 2px 1rem 2px 1rem;
            min-width: 159px;
            min-height: 60px;
            & > i {
              color: $primary;
              transform: rotate(180deg);
              font-size: 2rem;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.text-orange {
  color: $primary;
}
