@import "../../../styles/variables/color";

.activity-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $layout-dark;
  padding: 1rem;
  border-radius: 0.2rem;
  &-simulator-name {
    font-size: 1.2rem;
    text-align: center;
    margin: 0.5rem;
  }
  &-main-text {
    font-size: 3rem;
    text-align: center;
    margin: 1rem;
  }
  &-legend {
    font-size: 1.3rem;
    text-align: center;
    margin: 0rem;
    font-weight: bold;
  }

  &-simulation-time {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-style: italic;
    color: $text-color-disabled;
    margin-top: 0.5rem;

    & span {
      margin-bottom: 0.3rem;
    }
  }
}
