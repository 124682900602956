$color-primary: #fa9600;
$color-primary-dark: #cf7c00;
$color-background-dark: #232a35;
@import "../../styles/variables/color";
@import "../../styles/variables//layout";

@mixin userSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.acreos-select {
  @include userSelect();
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 0.5rem;
  & > div {
    display: flex;
    width: 100%;
  }
}

.acreos-select-column {
  & > div {
    text-align: left;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

.acreos-select_row {
  & label {
    width: 33%;
  }
  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}

.acreos-select_grey {
  & .acreos-select_selected {
    background-color: $layout-dark;
    border-color: $layout-dark;
  }
}

.acreos-select_selected {
  cursor: pointer;
  border: 2px solid $color-primary;
  background-color: $color-background-dark;
  min-height: 3.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  position: relative;
  width: 100%;

  &.selected {
    border-color: $color-primary;
  }

  &.invalid {
    border-color: $invalid;
  }

  & .icons {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    & i {
      font-size: 0.6rem;
      position: relative;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

.acreos-select_options {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  left: 0;
  top: 2.5rem;
  max-height: calc(6 * 1.5rem);
  overflow-y: scroll;
}

.acreos-select_clearable {
  width: 1.5rem;
  font-size: 12px;
}

.acreos-select_options {
  position: absolute;
  background-color: $color-background-dark;
  width: 100%;
  padding: 0.5rem 0;
  border: 2px solid $layout-middle;
  border-radius: 5px;
  margin-top: 14px;
  z-index: 100;
  box-shadow: 2px 2px 5px 0 rgb(17, 17, 17);
}

.acreos-select_option {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 0.5rem;
  color: $text-color-primary;
  &:hover {
    background-color: $layout-middle;
  }
  &.selected {
    & > i {
      font-size: inherit;
    }
    border-radius: $border-radius-small;
    background-color: $primary;
    color: $text-color-inversed;
  }
  & i {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }
}

.acreos-select_error-message {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: $invalid;
  min-height: 1.5rem;
}

.hide {
  display: none;
}

.acreos-select_default {
  display: flex;
  align-items: center;
  & i {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }
}

.custom-field_select {
  & .acreos-select_selected {
    background-color: $layout-dark-shadow;
    border-color: $layout-light;
    &.invalid {
      border-color: $invalid;
    }
  }
}

.color-text-primary {
  color: $text-color-primary !important;
}
