// color

//layout
$layout-light: #5b616b;
$layout-main: #393f49;
$layout-middle: #393f49;
$layout-dark: #232a35;
$layout-dark-shadow: #191e27;
$layout-dark-light: #282e39;
$layout-dark-1: #2d3541;
$layout-dark-2: #282f3a;

//color
$primary: #fa9600;
$primary-light: #f39c1a;
$primary-disabled: #e6c28c;
$primary-dark: #cf7c00;
$valid: #32936f;
$invalid: #e84b58;
$primary-half-opacity: #694616;

//text-color
$text-color-primary: #e9e9e9;
$text-color-disabled: #a09e9e;
$text-color-inversed: #393f49;
$title-blue: #9fb3e8;
$text-color-disable: #ababab;

//circle dot generator
//https://kovart.github.io/dashed-border-generator/
