$layout-middle: #393f49;
@import "../../styles/variables/color";

@import "../../styles/variables/layout";

.machine-summary-placholder {
  height: 300px;
  width: 100%;
  position: relative;
}

.placholder-animation {
  position: absolute;
  left: -40%;
  height: 100%;
  width: 20%;
  background-image: linear-gradient(
    to left,
    rgba($layout-middle, 0.05),
    rgba($layout-middle, 0.3),
    rgba($layout-middle, 0.6),
    rgba($layout-middle, 0.3),
    rgba($layout-middle, 0.05)
  );
  background-image: -moz-linear-gradient(
    left,
    rgba($layout-middle, 0.05),
    rgba($layout-middle, 0.3),
    rgba($layout-middle, 0.6),
    rgba($layout-middle, 0.3),
    rgba($layout-middle, 0.05)
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba($layout-middle, 0.05),
    rgba($layout-middle, 0.3),
    rgba($layout-middle, 0.6),
    rgba($layout-middle, 0.3),
    rgba($layout-middle, 0.05)
  );
  animation: loading 1s infinite;
  z-index: 45;
}

@keyframes loading {
  0% {
    left: -40%;
  }
  100% {
    left: 80%;
  }
}

.result-per-machine {
  transition: all 0.5s;
  border-radius: $border-radius-small;
  background-color: $layout-dark;
  width: 100%;
  min-height: 300px;
  position: relative;
  padding-left: 0;
}

.result-per-machine-skills {
  width: 100%;
  padding: 0 1rem 1rem 1rem;
}

.result-per-machine .machine-icon {
  font-size: 3rem;
}

.result-per-machine .machine-icon {
  font-size: 3rem;
}

.result-per-machine-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
  padding-left: 0.5rem;
  & > h1 {
    font-size: 1.5rem;
    flex-shrink: 2;
  }
  &-spans {
    display: flex;
    flex-grow: 2;
    justify-content: flex-end;
    gap: 1rem;
    padding-right: 2rem;

    > div:nth-child(-n + 2) {
      border-right: 2px solid $text-color-disabled;
      padding-right: 1rem;
    }
  }
}

.result-per-machine_global.active .result-per-training_steps {
  border-radius: $border-radius-small $border-radius-small 0 0;
}

.span-title {
  font-size: 0.8rem;
}

.student-training--separator {
  border-bottom: 2px solid $text-color-disabled;
  margin: 0 2rem;
}

.title-part {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.machine-summary-tooltip .tooltip-content {
  border: 2px solid $text-color-primary;
  border-radius: unset;
  color: $text-color-primary;

  &::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: $layout-dark;
    border: 2px solid $text-color-primary;
    box-sizing: border-box;
  }
}
