@import "../../../styles/variables/color";
@import "../../../styles/variables/layout";

.calendar {
  color: $text-color-primary;
  font-weight: bold;
  background-color: $layout-dark;
  padding: 1rem;
  display: flex;
  border-radius: 0.2rem;
  flex-direction: column;
  height: 100%;

  & > div:first-child {
    width: 100%;
  }
  & > div:last-child {
    width: 100%;
  }
  @media screen and (max-width: $xlarge) {
    & > div:first-child {
      width: 100%;
    }
    & > div:last-child {
      width: 100%;
    }
  }

  &-student {
    @extend .calendar;
    background-color: transparent;
  }
}

.calendar-day {
  border: 1px solid $text-color-inversed;
  border-radius: unset;
  color: $text-color-primary;
  padding: 0.5rem;
  &.highlight {
    background: $primary;
    color: $text-color-inversed;
  }
  
  & .number {
    text-align: left;
    cursor: default;
  }

  & .activity {
    font-weight: normal;
    cursor: default;
  }

  &-none {
    border: 0;
  }

  &:focus {
    outline: none;
  }
  &::after {
    transition: all 0.4s;
    content: "";
    position: absolute;
    z-index: -1;
  }

  & .tooltip {
    display: grid;
    grid-auto-flow: row;
    background: transparent;
    cursor: default;
    position: relative;
    & .icon-search {
      position: absolute;
      right: 0;
      font-size: 0.6rem;
    }
  
    &-content {
      border: 2px solid $text-color-primary;
      border-radius: unset;
      color: $text-color-primary;

      &::before {
        content: "";
        position: absolute;
        top: -6px; 
        left: 50%;
        transform: translateX(-50%) rotate(45deg); 
        width: 10px;
        height: 10px;
        background-color: $layout-dark;
        border: 2px solid $text-color-primary;
        box-sizing: border-box;
      }
    }
    
    & .number {
      text-align: left;
      cursor: default;
    }
  
    & .activity {
      font-weight: normal;
      cursor: default;
    }
  
    &-none {
      border: 0;
    }
  
    &:focus {
      outline: none;
    }
    &::after {
      transition: all 0.4s;
      content: "";
      position: absolute;
      z-index: -1;
    }

    
  }
  
}

@mixin calendarWeek {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
  grid-gap: 0rem;
  grid-auto-flow: column;
}

.calendar-week-number {
  @include calendarWeek;
}

.calendar-header {
  @include calendarWeek;
  padding-bottom: 1.5rem;
  & span {
    cursor: default;
  }
}

.calendar-legend {
  display: flex;
  align-items: center;
  margin: 1rem;
  margin-bottom: 0;
  justify-content: space-evenly;
  &-label {
    margin-right: 1rem;
    cursor: default;
  }

  &-student {
    @extend .calendar-legend;
    font-size: unset;
    margin: 4rem;
    margin-left: 0rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 2.9rem;
    margin-right: 8rem;
  }

  & .legend-detail {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
    color: $text-color-disabled;
    & span {
      cursor: default;
    }
  }
  & .legend-icon {
    width: 10px;
    height: 10px;
    margin-right: 1rem;
  }
}

.tooltip .calendar-day,
.tooltip .calendar-day div {
  cursor: help;
}

.tooltip .calendar-day:hover {
  border: 1px solid white;
}

.tooltip > .calendar-day + .tooltip-text {
  font-size: 1.2rem;
  max-width: 230px;
}
