@import "../../styles/variables/color";
@import "../../styles/variables/layout";

$heightButton: 3rem;
$maxSettingsItemNav: 5;
$maxHomeItemNav: 6;

.acreos-vertical-nav-menu {
  position: relative;
  max-width: 350px;
  min-width: 250px;

  &_nav {
    height: calc(100% + 1px);

    & a {
      height: calc(100% / $maxSettingsItemNav);
    }
  }

  &_pagination {
    display: flex;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;

    & button {
      margin: 0;
      height: $heightButton;
      width: 50%;
      border: 1px solid;
    }

    & i {
      font-size: 2.5rem;

      &[direction="top"] {
        transform: rotate(90deg);
      }

      &[direction="down"] {
        transform: rotate(-90deg);
      }
    }
  }
}

.acreos-vertical-nav-menu.extended .acreos-vertical-nav-menu_nav {
  height: calc(100% - $heightButton);
}

.acreos-horizontal-nav-menu {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & a {
    &:active {
      -webkit-tap-highlight-color: transparent;
    }
  }

  & i {
    font-size: 2.5rem;
  }

  &_nav {
    display: flex;
    align-items: center;
  }
}

.gsta-navigation-link {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: $text-color-primary;
  text-decoration: none;
  font-weight: bold;
  gap: 0.5rem;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: $layout-dark;
  }

  &.active {
    background-color: $primary;
    color: $text-color-inversed;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 15%;
      height: 1px;
      width: 70%;
      border-bottom: 1px solid $layout-dark-shadow;
      z-index: 6;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0%;
      left: 15%;
      height: 1px;
      width: 70%;
      border-bottom: 1px solid $primary;
      z-index: 6;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 15%;
    height: 1px;
    width: 70%;
    border-bottom: 1px solid $text-color-disabled;
    z-index: 5;
  }
}
