$primary: #fa9600;
$text-color-disabled: #a09e9e;
$layout-middle: #31363f;
$layout-main: #393f49;

.reorder-cell {
  & i:hover {
    cursor: pointer;
    color: $primary;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  & button:first-child {
    transform: rotate(-90deg);
  }
  & button:last-child {
    transform: rotate(90deg);
  }
  & button {
    display: flex;
    margin: 0.2rem;
    background-color: transparent;
    font: inherit;
    color: inherit;
    border: none;
    &:disabled,
    &:disabled:hover {
      color: $text-color-disabled;
      & > i {
        color: $text-color-disabled;
      }
    }
    &:disabled:hover {
      cursor: not-allowed;
      & > i {
        cursor: not-allowed;
      }
    }
  }
  & input {
    margin: 0;
    font: inherit;
    color: inherit;
    background-color: $layout-middle;
    border: solid 1px $layout-main;
    outline: none;
    width: 4rem;
    text-align: center;
  }

  & input[type="number"] {
    -moz-appearance: textfield;
  }

  & input::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
