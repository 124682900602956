@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.export-pdf-options {
  position: absolute;
  z-index: 1;
  border: 1px solid $primary;
  border-radius: $border-radius-small;
  overflow: hidden;
  & > button {
    width: 100%;
    background-color: $layout-dark-shadow;
    padding: 0.5rem;
    color: $text-color-disabled;
    border: 1px solid $layout-dark-shadow;
    height: 100%;
    display: flex;
    cursor: pointer;
    & i {
      margin-right: 0.3rem;
    }
    & span {
      white-space: nowrap;
    }
  }
  & > button:hover {
    background-color: $layout-dark-light;
  }
}
