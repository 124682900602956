@import "../../styles/variables/color";

.modal.import-student-modal {
  & .modal-content {
    width: 45%;
    height: 80%;
    overflow-y: hidden;
    justify-content: unset;

    & .modal-action {
      padding: 1rem 2rem;
      justify-content: space-between;
      width: 100%;
      min-height: 10%;
    }
    & .modal-main {
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
    }
  }

  & input {
    display: none;
  }

  & .import-student {
    display: flex;
    align-items: center;
    border: 1px solid $text-color-disabled;
    padding-right: 0.5rem;
    border-radius: 0.3rem;
    width: 80%;
    min-width: 50%;
    color: $text-color-disabled;
    transition: 0.1s ease-in-out;

    & > span {
      margin-left: 2rem;
    }

    & button.gsta-button-outlined {
      width: 35%;
      background-color: $layout-main;
      transition: 0.1s ease-in-out;
    }
    &:hover,
    :active {
      border-color: $primary;
      color: $text-color-primary;
      cursor: pointer;
      & button.gsta-button-outlined {
        background-color: $layout-dark;
      }
    }
  }

  & .error {
    color: $invalid;
    margin-top: 0.3rem;
    width: 80%;
    height: 2.5rem;
    &.hidden {
      visibility: hidden;
    }
  }

  & .warning-column {
    margin-top: 0.5rem;
    color: $primary;
    & i {
      margin-right: 0.5rem;
    }
  }
}
