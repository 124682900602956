@import "../../styles/variables/color";

.pdf-setting-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  align-items: center;
  gap: 1rem;

  & .image-selector {
    border-right: 1px solid $layout-light;

    img {
      max-width: 100%;
      max-height: fit-content;
      object-fit: cover;
    }
  }
}
