@import "../../styles/variables/color";

.modal.imported-student-modal_verification {
  & .modal-content {
    width: 80%;
    height: 75%;
  }

  & .modal-main {
    & .acreos-date-picker_input > section > div {
      border-color: $layout-dark-shadow;
      border-radius: 4px;
    }
    & .gsta-table-complete {
      & .icon-warning {
        font-size: 1.3rem;
        color: $invalid;
      }
      & .gsta-input input {
        background-color: $layout-dark-shadow;
        border-color: $layout-dark-shadow;
      }

      & .acreos-date-picker_input .error section div {
        border-color: $layout-dark;
      }

      & .acreos-select_selected {
        border-color: $layout-dark;
        background-color: $layout-dark-shadow;
      }
    }
    & div h3 {
      display: flex;
      align-items: center;
      margin-top: 0;
      color: $text-color-disabled;
      cursor: pointer;
      & i {
        margin-right: 0.5rem;
        font-size: 2rem;
      }
    }
  }

  & .modal-action {
    justify-content: space-between;
    margin: 0 1rem;
    & p {
      text-align: center;
      color: $invalid;
    }
  }

  & .gsta-table-complete-header {
    margin-left: 1rem;
  }
}
