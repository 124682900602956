@import "../ImportStudentsModalStepOne/ImportStudentsModalStepOne.style.scss";

.modal.imported-student-modal {
  @extend .import-student-modal;
  & .modal-main {
    & div:first-of-type {
      width: 100%;
      & > h3 {
        display: flex;
        align-items: center;
        margin-top: 0;
        color: $text-color-disabled;
        cursor: pointer;
        & > i {
          margin-right: 0.5rem;
          font-size: 2rem;
        }
      }
      & .error-column-number {
        color: $invalid;
        & span {
          margin-left: 0.5rem;
          cursor: default;
        }
      }
    }
    overflow-y: scroll;
    align-items: center !important;

    @media screen and (max-width: 1400px) {
      align-items: flex-start !important;
    }
  }

  & .modal-content {
    width: 80%;
  }

  & .modal-action {
    margin: 0;
    & p {
      text-align: center;
      color: $invalid;
    }
  }

  & .imported-student-modal_table-container {
    & table {
      & thead {
        display: flex;
        justify-content: flex-end;
      }
      & tbody > tr:nth-child(-n + 2) {
        border-top: 2px solid $text-color-disabled;
      }
      & tbody > tr:first-child {
        height: 4.5rem;
      }
      & tbody {
        & tr {
          height: 3.5rem;
          display: flex;
          justify-content: space-around;
          align-items: center;
          border-top: 1px solid #444a53;
          & td {
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 10rem;
            display: block;
            overflow: hidden;
          }

          & .acreos-select_selected {
            display: flex;
            justify-content: center;
            border-color: $text-color-disabled;
            color: $text-color-disabled;
            & span {
              width: 100%;
            }
            & div:first-of-type {
              width: fit-content;
              display: flex;
              & i {
                transform: rotate(-90deg);
                margin: 4px;
              }
            }

            & .acreos-selected_options {
              & span {
                width: 100%;
              }
            }
          }
        }
        & .acreos-select_clearable {
          position: unset;
        }
        & td {
          text-align: center;
          width: 10%;
        }
        & th {
          width: 13.7rem;
          margin: 0 0.5rem;
        }
      }
    }
  }
}
