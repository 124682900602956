@import "../../styles//variables/color";
@import "../../styles//variables/layout";

.acreos-tabs {
  width: 100%;
  & > ul {
    display: flex;
    width: calc(100% - 2rem);
    list-style-type: none;
    background-color: $layout-dark-shadow;
    margin: 0 1rem;
    padding: 1rem 0;
    border-radius: $border-radius-small;
    position: relative;
    border: solid 1px $layout-light;
    & > div {
      background-color: rgba($color: $primary, $alpha: 0.4);
      border-radius: 3px;
      position: absolute;
      height: calc(100% - 1rem);
      top: 0;
      transition: left 0.5s;
      border: solid 1px $primary;
    }
    & > li {
      display: flex;
      text-align: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      z-index: 2;
    }
  }
}
