@import "../../styles/variables/color";
@import "../../styles/variables/layout";

.gsta-table-search-input {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1100px) {
    width: 50%;
  }
}

.search-bar-separator {
  margin: 0;
  padding: 0;
  height: 0.2rem;
  border: none;
  background-color: $layout-main;
}

.gsta-table-search-bar {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 15% 60% 25%;
  width: 100%;
  min-height: 7rem;
  background-color: $layout-dark;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-right: 1rem;

  &-no-grouped-actions {
    @extend .gsta-table-search-bar;
    grid-template-columns: 75% 25%;
  }

  &-no-search {
    @extend .gsta-table-search-bar;
    grid-template-columns: 25% 75%;
  }

  &-filter {
    @extend .gsta-table-search-bar;
    grid-template-columns: 100%;
  }

  &_filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    gap: 0.5rem;
    padding: 1rem;

    &-text {
      margin-right: 1rem;
      white-space: nowrap;
      width: auto;

      @media screen and (max-width: 1400px) {
        font-size: 0.8rem;
        width: 15%;
      }
    }

    &-notext {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }
}

.vertical-separation {
  border-right: 2px solid $layout-main;

  @media (max-width: 1100px) {
    border-right: none;
    border-bottom: 2px solid $layout-main;
  }
}

$test-name: "testName";

.lol-#{$test-name} {
  width: 300rem;
}

.full-height-search-component {
  height: 100%;
}
