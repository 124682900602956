@import "../../styles/variables/color";

.center-parameters-content {
  padding: 1rem;
  background-color: $layout-dark;
  border-radius: 10px;
  margin-top: 2.4rem;
  margin-left: 1.9rem;
  box-shadow: 0 4px 8px $layout-dark;

  & h1 {
    font-size: x-large;
    font-weight: bold;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  & .modules-authorization_content {
    display: flex;
    flex-direction: column;
  }
}

.center-parameters-title {
  font-weight: bold;
  font-size: xx-large;
  margin-left: 2rem;
  margin-top: 2.3rem;
}

.hr-without-margin-top {
  margin-top: 0;
  width: 100%;
}
