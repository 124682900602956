@import "../../styles/variables/color";

.acreos-date-picker_range {
  display: flex;
  flex-direction: column;
  & > article {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  & > p {
    color: $invalid;
  }
}
