@import "../../styles/variables/color";

.patch-notes-title {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  & h1 {
    color: $primary;
  }
}
