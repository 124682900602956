.gsta-table-search-bar_filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  gap: 0.5rem;
  padding: 1rem;

  &-chips {
    display: flex;
    flex-wrap: wrap;
    width: 65%;

    & span {
      @media screen and (max-width: 1400px) {
        font-size: 0.8rem;
      }

      @media screen and (max-width: 1100px) {
        font-size: 0.7rem;
      }
    }
  }

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }
}
