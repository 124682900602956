$text-color-disabled: #a09e9e;

.create-custom-training-main-information {
  margin: 2rem 0 0 2rem;

  & h2 {
    border-bottom: 1px solid $text-color-disabled;
    padding-bottom: 1rem;
    width: 80%;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  & .gsta-input.training-name {
    margin-top: 1rem;
    width: 30%;
  }

  & .gsta-input.training-description {
    margin-top: 2rem;
    width: 45%;
    & > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      & label {
        width: unset;
      }
    }
  }

  & .acreos-select {
    width: 30%;

    &_selected {
      border: none;
      & span {
        color: $text-color-disabled;
      }
    }
  }

  & .acreos-select.machine {
    margin-top: 3rem;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    & label {
      margin-bottom: 1rem;
    }
  }
}
