.student-details-global-layout {
  padding-bottom: 3rem;
}

.header-student-detail-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;

  @media screen and (max-width: 1400px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 1100px) {
    font-size: 0.8rem;
  }
}

.header-student-detail {
  display: flex;
  align-items: center;
  align-self: flex-start;
  &-name {
    cursor: default;
  }

  &-simulator-access {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    cursor: default;

    &-span {
      margin-right: 1rem;
    }
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 1rem;
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
    & .gsta-button-outlined > span {
      margin: 0;
    }

    & .export-pdf-options {
      margin-top: 7rem;
      margin-left: -13rem;
      @media (max-width: 1100px) {
        margin-top: 3rem;
        margin-left: 1.5rem;
      }
    }
  }
}
